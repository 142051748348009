import React from "react";
import { Switch } from "../ui/switch";
import { Label } from "../ui/label";

export default function AppSwitch({
  onChange = () => {},
  value,
  style,
  className,
  color,
  label,
  cusClass,
}) {
  return (
    <div
      className={cusClass}
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {label && (
        <Label className="mb-3" htmlFor="inputId">
          {label}
        </Label>
      )}
      <Switch
        checked={value}
        onCheckedChange={(checked) => {
          onChange(checked); // Use the actual checked state from the event
        }}
        style={style}
        className={className}
        color={color}
      />
    </div>
  );
}
