import React, { useEffect, useState } from "react";
import moment from "moment";
import { AppSelect } from "../../../components/appComponents";
// import Common from "../../../helper/common";
import { Button } from "../../../components/ui/button";
import {
  PaymentTypeEnum,
  TransactionLogStatusEnum,
  TransactionLogTypeEnum,
  ViewDateFormat,
  ViewDateTimeFormat,
  ViewTimeOnly,
} from "../../../constants/appConstant";
import AdvanceTable from "../../../components/advanceTable";
import Common from "../../../helper/common";

const TransactionLogs = () => {
  const [data, setData] = useState({});
  // const [courtId, setCourtId] = useState(null);
  const [status, setStatus] = useState(null);
  const [transactionType, setTransactionType] = useState(null);
  const [paymentType, setPaymentType] = useState(null);

  useEffect(() => {
    getData(1);
    Common.delay();
  }, []);

  useEffect(() => {
    getData(1);
  }, [status, transactionType, paymentType]);

  // const getCourts = async () => {
  //   const response = await Common.ApiService.getInstance().request(
  //     "GetCourtddl"
  //   );
  //   const courts = [{ id: null, name: "All" }, ...(response?.data ?? [])];
  //   setCourtList(courts);
  // };

  const resetFilters = () => {
    setStatus(null);
    setTransactionType(null);
    setPaymentType(null);
  };

  const getData = async (
    pageNumber,
    searchTerm = "",
    recordsToDisplay = null
  ) => {
    const response = await Common.ApiService.getInstance().request(
      "GetTransactionLogs",
      {
        pageNumber: pageNumber,
        q: searchTerm,
        isactive: true,
        recordsToDisplay,
        status,
        transactionType,
        paymentType,
      },
      "Post"
    );
    if (response?.data) {
      let obj = {
        ...response.data[0],
        data: response.data[0].data.map((item) => ({
          ...item,
          bookingDate: item.bookingDate
            ? `${moment(item.bookingDate).format(ViewDateFormat)}`
            : "-",
          // createdOn: `${moment(item.createdOn).format(ViewDateTimeFormat)}`,
          createdOn: Common.Utility.ConvertTime(item.createdOn),
          isByClub: item.isByClub ? "Yes" : "No",
          isPlayerCancel: item.isPlayerCancel ? "Yes" : "No",
          isTimeOut: item.isTimeOut ? "Yes" : "No",
          startDateTime: item.startDateTime
            ? `${moment(item.startDateTime).format(ViewTimeOnly)}`
            : "-",
          endDateTime: item.endDateTime
            ? `${moment(item.endDateTime).format(ViewTimeOnly)}`
            : "-",
          updatedOn: item.updatedOn
            ? `${moment(item.updatedOn).format(ViewDateTimeFormat)}`
            : "-",
          postion: item.position ?? "-",
          side: item.side ?? "-",
          err_code: item.err_code ?? "-",
          err_message: item.err_message ?? "-",
          paymentType: Common.Utility.getKeyByValue(
            PaymentTypeEnum,
            item.paymentType
          ),
          status: Common.Utility.getKeyByValue(
            TransactionLogStatusEnum,
            item.status
          ),
          transactionType: Common.Utility.getKeyByValue(
            TransactionLogTypeEnum,
            item.transactionType
          ),
        })),
      };

      setData(obj);
    }
  };

  // const _addUpdateTrigger = () => (
  //   <Button size="icon" variant="outline" color="secondary" className="h-7 w-7">
  //     <Icon icon="heroicons:pencil" className="h-4 w-4" />
  //   </Button>
  // );

  // const actions = [
  //   {
  //     type: "view",
  //     onClick: (val) => {
  //       router.push(`/ clubbookings / detail ? id = ${ val.id }`, {});
  //     },
  //   },
  // ];

  let columns = [
    { title: "Basket ID", type: "text", key: "basketId" },
    { title: "Player Name", type: "text", key: "playerName" },
    { title: "Amount", type: "text", key: "amount" },
    { title: "Status", type: "text", key: "status" },
    { title: "Transaction Type", type: "text", key: "transactionType" },
    { title: "Court", type: "text", key: "courtName" },
    { title: "CreatedOn", type: "text", key: "createdOn" },
    { title: "Booking Date", type: "text", key: "bookingDate" },
    { title: "Start", type: "text", key: "startDateTime" },
    { title: "End", type: "text", key: "endDateTime" },
    { title: "By Club", type: "text", key: "isByClub" },
    { title: "Player Cancelled", type: "text", key: "isPlayerCancel" },
    { title: "Payment Type", type: "text", key: "paymentType" },
    { title: "Error Message", type: "text", key: "err_message" },
    { title: "Error Code", type: "text", key: "err_code" },
    { title: "Position", type: "text", key: "position" },
    { title: "Side", type: "text", key: "side" },
    { title: "Completed On", type: "text", key: "updatedOn" },
  ];

  return (
    <>
      <div className="md:grid md:grid-cols-6 gap-3 ">
        {/* <AppSelect
          value={courtId}
          onChange={setCourtId}
          placeholder={"Select Court"}
          label={"Court"}
          data={courtList}
          className={"mb-5 col-span-1"}
        /> */}

        <AppSelect
          value={status}
          onChange={setStatus}
          label="Transaction Status"
          data={[
            { id: null, name: "Select" },
            ...Common.Utility.enumToArray(TransactionLogStatusEnum),
          ]}
          className="mb-5 col-span-1"
        />

        <AppSelect
          value={transactionType}
          onChange={setTransactionType}
          label="Transaction Type"
          data={[
            { id: null, name: "Select" },
            ...Common.Utility.enumToArray(TransactionLogTypeEnum),
          ]}
          className="mb-5 col-span-1"
        />

        <AppSelect
          value={paymentType}
          onChange={setPaymentType}
          label="Payment Type"
          data={[
            { id: null, name: "Select" },
            ...Common.Utility.enumToArray(PaymentTypeEnum),
          ]}
          className="mb-5 col-span-1"
        />

        <Button className="mt-6 h-8" onClick={resetFilters}>
          Reset Filters
        </Button>
      </div>

      {data?.data && (
        <AdvanceTable
          title={"Transaction Logs"}
          columns={columns}
          data={data}
          getData={getData}
          showPagination
          showAddButton
        />
      )}
    </>
  );
};

export default TransactionLogs;
