// import avatar1 from "@/public/images/avatar/avatar-7.jpg";
// import avatar3 from "@/public/images/avatar/avatar-3.jpg";
// import avatar4 from "@/public/images/avatar/avatar-4.jpg";
// import avatar5 from "@/public/images/avatar/avatar-5.jpg";
// import avatar6 from "@/public/images/avatar/avatar-6.jpg";
// import ImageConstant from '../../../../constants/imageConstant'

import { ImageConstant } from "../../../../constants/imageConstant";

const {
  avatar1,

  avatar3,
  avatar4,
  avatar5,
  avatar6,
} = ImageConstant;

export const data = [
  {
    id: 1,
    name: "Alex Brooks",
    email: "bellas@gmail.com",
    score: "90",
    image: avatar1,
    color: "success",
    amount: "2,000",
  },
  {
    id: 2,
    name: "Maya Rodriguez",
    email: "bellas@gmail.com",
    score: "80",
    image: avatar6,
    color: "primary",
    amount: "1,900",
  },
  {
    id: 3,
    name: "Connor Patel",
    email: "bellas@gmail.com",
    score: "70",
    image: avatar3,
    color: "primary",
    amount: "1,800",
  },
  {
    id: 4,
    name: "Monica Bellas",
    email: "bellas@gmail.com",
    score: "65",
    image: avatar4,
    color: "info",
    amount: "1,600",
  },
  {
    id: 5,
    name: "Zoe Thompson",
    email: "bellas@gmail.com",
    score: "60",
    image: avatar5,
    color: "warning",
    amount: "1,500",
  },
  {
    id: 6,
    name: "Olivia Li",
    email: "bellas@gmail.com",
    score: "50",
    image: avatar6,
    color: "warning",
    amount: "1,400",
  },
  {
    id: 7,
    name: "Jackson Kim",
    email: "bellas@gmail.com",
    score: "45",
    image: avatar1,
    color: "destructive",
    amount: "1,300",
  },
];
