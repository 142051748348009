import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { CardContent } from "../../../components/ui/card";
import Card from "../../../components/ui/card-snippet";

const TypeformatsCard = ({
    formatsFormikProps,
    formikProps,
    title,
    data = [],
    onRemove = () => { },
    nameKey = "name",
    valueKey = "value",
}) => {
    const [formats, setFormats] = useState([]);

    useEffect(() => {
        setFormats(Array.isArray(data) ? data : []);
    }, [data]);


    const editFormat = (format, index) => {
        formatsFormikProps.setFieldValue("name", format[nameKey]); 
        formatsFormikProps.setFieldValue("value", format[valueKey]); 
        formatsFormikProps.setFieldValue("isEdit", true);
        formatsFormikProps.setFieldValue("currentFormat", format);
        formatsFormikProps.setFieldValue("index", index);
    };

    return formikProps && data.length > 0 ? (
        <div>
            <Card title={title} style={{ fontSize: "medium", fontWeight: 700 }}>
                <CardContent className="p-0">
                    <table className="table-auto w-full">
                        <thead>
                            <tr>
                                <th key="name">Name</th> 
                                <th key="value">Value</th>
                            </tr>
                        </thead>
                        <tbody style={{ textAlign: "center" }}>
                            {formats.map((entry, index) => (
                                <tr key={index}>
                                    <td style={{ fontSize: "16px" }}>{entry[nameKey]}</td>
                                    <td style={{ fontSize: "16px" }}>{entry[valueKey]}</td>
                                    <td
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "4px",
                                        }}
                                    >
                                        <Icon
                                            icon="heroicons:x-circle"
                                            className="h-5 w-5"
                                            style={{ color: "red", cursor: "pointer" }}
                                            onClick={() => onRemove(entry)}
                                        />
                                        <Icon
                                            icon="heroicons:pencil"
                                            className="h-4 w-4"
                                            style={{ color: "#5f5fc5", cursor: "pointer" }}
                                            onClick={() => editFormat(entry, index)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </CardContent>
            </Card>
        </div>
    ) : null;
};

export default TypeformatsCard;
