import { useEffect, useState } from "react";
import { loader } from "react-global-loader";
import { Icon } from "@iconify/react";
import AddCountries from "./components/addCountries";
import { Button } from "../../../components/ui/button";
import Common from "../../../helper/common";
import AdvanceTable from "../../../components/advanceTable";

const Countries = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [refId, setRefId] = useState(null);

    useEffect(() => {
        // loader.hide();
        getData();
    }, []);

    useEffect(() => {
        if (!open && refId) {
            setRefId();
        }
    }, [open]);

    const getData = async () => {
        setLoading(true);
        const response = await Common.ApiService.getInstance().request(
            "GetCountries"
        );
       setData(response?.data);
        setLoading(false);
    };

    const _handleDelete = async (id) => {
        const isConfirmed = await Common.SweetAlert.confirmation(
            "Are you sure you want to delete this Country?",
            "Delete Country"
        );
        if (isConfirmed) {
            let resp = await Common.ApiService.getInstance().request(
                `DeleteCountry?id=${id}`,
                {},
                "Delete"
            );
            Common.SweetAlert.alert(resp.message, "success");
            setTimeout(() => {
                getData(1);
            }, 500);
        }
    };

    const _addUpdateTrigger = () => (
        <Button size="icon" variant="outline" color="secondary" className="h-7 w-7">
            <Icon icon="heroicons:pencil" className="h-4 w-4" />
        </Button>
    );

    const _onEdit = async (id) => {
        setOpen(true);
        setRefId(id);
    };

    const actions = [
        {
            type: "edit",
            onClick: (val) => _onEdit(val.id),
        },
        {
            type: "delete",
            onClick: (val) => _handleDelete(val.id),
        },

    ];

    const _thead = [
        { title: "Id", type: "number", isVisible: false, key: "id" },
        { title: "Country Name", type: "text", isVisible: true, key: "name" },
        { title: "Code", type: "text", isVisible: true, key: "code" },
        { title: "UCT Diff", type: "text", isVisible: true, key: "utcDiff" },
    ];

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <AdvanceTable
                title={"Countries"}
                columns={_thead.filter((item) => item.isVisible)}
                data={{ data: data }}
                getData={getData}
                addButton={() => (
                    <AddCountries
                        id={refId}
                        onClose={() => getData()}
                        open={open}
                        setOpen={setOpen}
                    />
                )}
                showPagination={false}
                showAddButton
                actionButtons={actions}
                updateTrigger={_addUpdateTrigger}
            />
        </>
    );
};

export default Countries;
