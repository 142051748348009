import React from "react";
import { Switch } from "../ui/switch";
import ErrorText from "./error-text";
import { Label } from "../ui/label";

export default function FormikSwitch({
  formikProps,
  onChange = () => {},
  name,
  style,
  className,
  color,
  label,
  text,
  cusClass,
  disabled = false,
}) {
  return (
    <div
      className={cusClass}
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {label && (
        <Label className="mb-3" htmlFor="inputId">
          {label}
        </Label>
      )}
      <Switch
        checked={formikProps.values[name]}
        onCheckedChange={(e) => {
          formikProps.setFieldValue(name, !formikProps.values[name]);
          onChange(!formikProps.values[name]);
        }}
        style={style}
        className={className}
        color={color}
        disabled={disabled}
      />

      <ErrorText name={name} formikProps={formikProps} />
    </div>
  );
}
