import React from "react";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "../ui/select";
import ErrorText from "./error-text";
import { Label } from "../ui/label";

export default function FormikSelect({
  formikProps,
  onChange = () => {},
  name,
  placeholder,
  data = [],
  labelKey = "name",
  valueKey = "id",
  label,
  labelButton,
  style,
  className,
  removeWrapper = false,
  disabled,
}) {
  return (
    // <div className="flex items-center space-x-2">
    //   <p className="text-sm font-medium text-muted-foreground whitespace-nowrap">
    //     Rows per page
    //   </p>
    //   <Select
    //     // className="z-[999]"
    //     value={formikProps.values[name]}
    //     onValueChange={(value) => formikProps.setFieldValue(name, value)}
    //   >
    //     <SelectTrigger className="h-8 w-[70px]">
    //       <SelectValue placeholder={15} />
    //     </SelectTrigger>
    //     <SelectContent className="z-[999]">
    //       {[15, 30, 50, 100].map((pageSize) => (
    //         <SelectItem key={pageSize} value={`${pageSize}`}>
    //           {pageSize}
    //         </SelectItem>
    //       ))}
    //     </SelectContent>
    //   </Select>
    // </div>
    <div>
      {/* 
      Commint by Zain , for Ui issue 
      <div className="flex justify-between">
        {label && (
          <Label className="mb-3" htmlFor="inputId">
            {label}
          </Label>
        )}
        {labelButton && labelButton()}
      </div> */}

      {/* Issue Solve Changes  */}
      {!labelButton ? (
        <>
          {label && (
            <Label className="mb-3" htmlFor="inputId">
              {label}
            </Label>
          )}
        </>
      ) : (
        <div className="flex justify-between">
          {label && (
            <Label className="mb-3" htmlFor="inputId">
              {label}
            </Label>
          )}
          {labelButton && labelButton()}
        </div>
      )}
      {/* Issue Solve Changes End /// */}
      <Select
        value={formikProps.values[name]}
        onValueChange={(value) => {
          formikProps.setFieldValue(name, value);
          if (onChange) {
            onChange(value);
          }
        }}
        disabled={disabled}
      >
        <SelectTrigger disabled={disabled}>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent className="z-[9999]">
          {data.map((item) => (
            <SelectItem value={item[valueKey]} disabled={disabled}>
              {" "}
              {item[labelKey]}
            </SelectItem>
          ))}
          {/* <SelectItem value="bd">Bangladesh</SelectItem>
          <SelectItem value="in">India</SelectItem>
          <SelectItem value="aus">Australia</SelectItem> */}
        </SelectContent>
      </Select>
      <ErrorText name={name} formikProps={formikProps} />

      {/* {formikProps.errors[name] && <div> {formikProps.errors[name]} </div>} */}
    </div>
  );
}
