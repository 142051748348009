import { useEffect, useState } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  BarChart,
  Bar,
  Tooltip,
  Legend,
} from "recharts";
import { Card, CardContent, CardHeader, CardTitle } from "../../../components/ui/card";
import { useThemeStore } from "../../../store";
import { useTheme } from "../../../hooks";
import { themes } from "../../../config/thems";
import axios from "axios";
import common from "../../../helper/common";
import { respEnum } from "../../../constants/appConstant";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="bg-background z-10 text-default-900 shadow-lg space-y-2 rounded-md pb-2">
        <div className="bg-default-50 rounded-t-md border-b border-default-200 py-1.5 px-2">
          {payload[0].payload.name}
        </div>
        {payload.map((item, index) => (
          <div
            key={`project-budget-bar-tooltip-${index}`}
            className="flex items-center gap-2 font-medium text-default-600 px-2 hover:bg-default-50"
          >
            <span
              className="w-2.5 h-2.5 rounded-full bg-primary"
              style={{ backgroundColor: `${item.color}` }}
            ></span>
            <span>
              {item.name === "app_bookings" && "App Bookings"}
              {item.name === "club_bookings" && "Club Bookings"}:
            </span>
            <span className="font-medium">{item.value}</span>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

const YAxisFormatter = (value) => `${value / 100}`;

const CustomLegend = ({ payload }) => (
  <ul className="flex justify-center gap-2 mt-2">
    {payload.map((entry, index) => (
      <li
        key={`rechart-legend-item-${index}`}
        className="flex items-center gap-1 text-xs font-medium text-default-500"
      >
        <span
          className="h-2 w-2 rounded-[2px] block"
          style={{ backgroundColor: `${entry.color}` }}
        />
        {entry.value === "app_bookings" && "App Bookings"}
        {entry.value === "club_bookings" && "Club Bookings"}
      </li>
    ))}
  </ul>
);

const ProjectBudgetBar = ({ height = 285 }) => {
  const { theme: config } = useThemeStore();
  const { theme: mode } = useTheme();
  const theme = themes.find((theme) => theme.name === config);

  const [data, setData] = useState([]);

  useEffect(() => {
    getMonthWiseBooking();

  }, []);


  const getMonthWiseBooking = async () => {
    let response = await common.ApiService.getInstance().request(
      `GetMonthlyBookingCounts`,
    );

    if (response.status == respEnum.Success) {
      let d = response?.data;
      setData(d);
    }
    else {
      setData([]);
    }
  }



  return (
    <Card className="h-full">
      <CardHeader className="border-none mb-4">
        <CardTitle className="mt-4">Bookings Chart</CardTitle>
      </CardHeader>
      <CardContent className="p-0">
        <ResponsiveContainer width="100%" height={height}>
          <BarChart height={height} data={data}>
            <CartesianGrid
              stroke={`hsl(${theme?.cssVars[mode === "dark" ? "dark" : "light"].chartGrid})`}
              strokeDasharray="3 3"
              vertical={false}
            />
            <Legend verticalAlign="bottom" height={36} content={<CustomLegend />} />
            <XAxis
              dataKey="name"
              tick={{
                fill: `hsl(${theme?.cssVars[mode === "dark" ? "dark" : "light"].chartLabel})`,
                fontSize: "12px",
              }}
              tickLine={false}
              stroke={`hsl(${theme?.cssVars[mode === "dark" ? "dark" : "light"].chartGrid})`}
              axisLine={false}
            />
            <YAxis
              tick={{
                fill: `hsl(${theme?.cssVars[mode === "dark" ? "dark" : "light"].chartLabel})`,
                fontSize: "12px",
              }}
              tickLine={false}
              axisLine={false}
              stroke={`hsl(${theme?.cssVars[mode === "dark" ? "dark" : "light"].chartGrid})`}
              tickFormatter={YAxisFormatter}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="app_bookings"
              fill={`hsl(${theme?.cssVars[mode === "dark" ? "dark" : "light"].success})`}
            />
            <Bar
              dataKey="club_bookings"
              fill={`hsl(${theme?.cssVars[mode === "dark" ? "dark" : "light"].success})`}
              fillOpacity={0.3}
            />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default ProjectBudgetBar;
