// import Image from "next/image";
// import admin from "@/public/images/all-img/admin.png"
import admin from "../../../assets/images/all-img/admin.png";
import Utility from "../../../helper/utilityHelper";

const WelcomeBlock = ({ bookingSummary }) => {
  const data = [
    {
      title: "Today's Bookings",
      total: Utility.formatNumber(bookingSummary?.todaysBookings),
    },
    {
      title: "Upcoming Bookings",
      total: Utility.formatNumber(bookingSummary?.upcomingBookings),
    },
  ];
  return (
    <div className="w-full h-full bg-primary rounded-md  flex p-6 relative ">
      <div className="flex-1 ">
        <div className="text-lg md:text-2xl font-semibold text-primary-foreground mb-6">
          Welcome Back <br />
          {global.user?.firstName}!
        </div>

        <div className=" flex flex-col gap-4 sm:flex-row ">
          {data.map((item, index) => (
            <div
              key={`welcome-text-${item.title}`}
              className="flex items-center w-full max-w-[130px] p-3 rounded bg-white/10 shadow backdrop-blur-sm"
            >
              <div className="flex-1">
                <div className="text-xs font-semibold text-primary-foreground/80">
                  {item.title}
                </div>
                <div className="text-lg font-semibold text-primary-foreground">
                  {item.total}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="absolute bottom-0 right-4 md:right-[30%] md:bottom-5 2xl:right-10 w-[100px] ">
        <img src={admin} alt="user" className="w-full h-full object-cover" />
      </div>
    </div>
  );
};

export default WelcomeBlock;
