import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import Common from "../../../helper/common";
import { Button } from "../../../components/ui/button";
import AdvanceTable from "../../../components/advanceTable";
import {
  HolidayTypeEnum,
  ViewDateTimeFormat,
} from "../../../constants/appConstant";
import { useNavigate } from "react-router-dom";

const CourtHolidays = () => {
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [customerId, setCustomerId] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getData(1);
  }, []);

  const getData = async (
    pageNumber,
    searchTerm = "",
    recordsToDisplay = null
  ) => {
    const response = await Common.ApiService.getInstance().request(
      "GetCluborCourtHolidays",
      {
        pageNumber: pageNumber,
        q: searchTerm,
        type: HolidayTypeEnum.Court,
      },
      "Post"
    );
    if (response?.data) {
      let obj = {
        ...response.data[0],
        data: response.data[0].data.map((item) => ({
          ...item,
          startDate: `${Common.moment(item.startDate).format(
            ViewDateTimeFormat
          )}`,
          endDate: `${Common.moment(item.endDate).format(ViewDateTimeFormat)}`,
          courtNames: item.courtNames.join(", "),
        })),
      };

      setData(obj);
    }
  };

  const _handleDelete = async (id) => {
    const isConfirmed = await Common.SweetAlert.confirmation(
      "Are you sure you want to delete this holiday?",
      "Delete Holiday"
    );
    if (isConfirmed) {
      let resp = await Common.ApiService.getInstance().request(
        `DeleteCluborCourtHoliday?id=${id}`,
        {},
        "Delete"
      );
      Common.SweetAlert.alert(resp.message, "success");
      setTimeout(() => {
        getData(1);
      }, 500);
    }
  };

  const _addUpdateTrigger = () => (
    <Button size="icon" variant="outline" color="secondary" className="h-7 w-7">
      <Icon icon="heroicons:pencil" className="h-4 w-4" />
    </Button>
  );

  const _addBtn = () => (
    <Button onClick={() => navigate("/app/addCourtHoliday")}>
      <span className="text-xl mr-1">
        <Icon icon="icon-park-outline:plus" />
      </span>
      Add Holiday
    </Button>
  );

  const actions = [
    {
      type: "edit",
      onClick: (val) => {
        navigate(`/app/addCourtHoliday?id=${val.id}`);
      },
    },
    {
      type: "delete",
      onClick: (val) => _handleDelete(val.id),
    },
  ];

  const renderActionButtons = (val) => (
    <div className="flex items-center">
      <Button
        size="icon"
        variant="outline"
        onClick={() => actions[0].onClick(val)}
      >
        <Icon icon="heroicons:pencil" className="h-4 w-4" />
      </Button>
      <Button
        size="icon"
        variant="outline"
        className="ml-2"
        onClick={() => actions[1].onClick(val)}
      >
        <Icon icon="heroicons:trash" className="h-4 w-4" />
      </Button>
    </div>
  );

  let columns = [
    { title: "From Date", type: "text", key: "startDate" },
    { title: "Till Date", type: "text", key: "endDate" },
    { title: "Courts", type: "text", key: "courtNames" },
    { title: "Reason", type: "text", key: "reason" },
    {
      title: "Actions",
      type: "custom",
      key: "actions",
      render: renderActionButtons,
    },
  ];

  return (
    <>
      {data?.data && (
        <AdvanceTable
          title={"Court Holidays"}
          columns={columns}
          data={data}
          getData={getData}
          addButton={_addBtn}
          showPagination
          showAddButton
          actionButtons={actions}
          updateTrigger={_addUpdateTrigger} // Example function
        />
      )}
    </>
  );
};

export default CourtHolidays;
