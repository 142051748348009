import React, { useEffect, useState } from "react";
import BookingDetailSidebar from "./components/detail-sidebar";
import BooingFurthurDetails from "./components/detail-table";
import { Icon } from "@iconify/react";
import { Formik } from "formik";
import * as yup from "yup";
import { useMediaQuery } from "../../../hooks";
import { cn } from "../../../helper/utilityHelper";
import {
  FormikInput,
  FormikSelect,
} from "../../../components/formik-components";
import { Button } from "../../../components/ui/button";
import DialogModal from "../../../components/partials/modal/dialogModal";
import { PaymentTypeEnum, RoleEnum, IntMinLevel, IntMax } from "../../../constants/appConstant";
import Common from "../../../helper/common";
import {
  BreadcrumbItem,
  Breadcrumbs,
} from "../../../components/ui/breadcrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import { CardContent, CardHeader, Card } from "../../../components/ui/card";

const BooingDetails = () => {
  const location = useLocation();
  const isDesktop = useMediaQuery("(max-width: 1280px)");
  const [openEdit, setOpenEdit] = useState(false);
  const [booking, setBooking] = useState({});
  const [showSidebar, setShowSidebar] = useState(true);
  const [id, setId] = useState();
  const navigate = useNavigate();
  const [showAddPayment, setShowAddPayment] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setId(params.get("id"));
    if (id) {
      getBookingDetails();
    }
  }, [id]);

  const handleEditSheetOpen = () => {
    setOpenEdit(!openEdit);
  };

  const AddPayment = async (d) => {
    setShowAddPayment(false);

    let data = {
      ...d,
      bookingId: booking?.id,
      paymentType: d.paymentType,
    };

    debugger;
    if (booking?.remainingAmount < data.amount) {
      Common.showToast(
        `Received amount must be less then ${booking?.remainingAmount} Rs`,
        "Error"
        );
      return;
      }

    let resp = await Common.ApiService.getInstance().request(
      "AddBookingPayment",
      data,
      "POST"
    );
    Common.SweetAlert.alert(resp.message, "success");
    setTimeout(() => {
      getBookingDetails();
    }, 500);
  };

  const getBookingDetails = async () => {
    let response = await Common.ApiService.getInstance().request(
      `BookingDetails?id=${id}`
    );
    let d = response?.data?.[0];

    const groupedResults = Array.isArray(d.result)
      ? d.result.reduce((acc, result) => {
          if (!acc[result.side]) {
            acc[result.side] = [];
          }
          acc[result.side].push(result);
          return acc;
        }, {})
      : {};

    const clubThumbnail = d.club?.images?.[0]?.path || "default-thumbnail.jpg";

    let _data = {
      ...d,
      club: {
        ...d.club,
        thumbnail: clubThumbnail,
      },
      groupedResults,
    };
    setBooking(_data);
  };

  const formValidations = yup.object({
      paymentType: yup.number().required("Payment Type Required"),
      amount: yup.number().required("Enter Amount").min(IntMinLevel, "Amount can't be negative or zero").max(IntMax,"Amount must be less than 99999999"),
  });

  const _renderAddPayment = () => (
    <div>
      <Formik
        onSubmit={AddPayment}
        validationSchema={formValidations}
        initialValues={{
          amount: booking?.remainingAmount,
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formikProps) => (
          <>
            <div className="mb-3">
              <FormikSelect
                formikProps={formikProps}
                name="paymentType"
                placeholder="Select"
                label="Payment Type"
                data={Common.Utility.enumToArray(PaymentTypeEnum)}
              />
            </div>

            <div >
              <FormikInput
                formikProps={formikProps}
                name="amount"
                placeholder="Received Amount"
                label="Received Amount"
                              type={"number"}
                              min={IntMinLevel}
                              onChange={(value) => {
                                  if (value < IntMinLevel && value != "") {
                                      formikProps.setFieldValue("amount", IntMinLevel);
                                  } else {
                                      formikProps.setFieldValue("amount", value);
                                  }
                              }}
              />
            </div>
            {/* <div>
              <label style={{ display: 'block', marginBottom: '5px' }}>Remaining Amount:</label>
              <div style={{ fontSize: '18px', fontWeight: 'bold' }}>{booking?.remainingAmount}</div>
            </div> */}

            <div style={{ marginTop: "20px", textAlign: "right" }}>
           
            <Button color="success"  onClick={(e) => {
                  e.preventDefault();
                  formikProps.handleSubmit();
                }} className="shadow-md mr-3">  Add Payment</Button>

<Button color="secondary"
            className="secondary"
            onClick={() => setShowAddPayment(false)}
          >
            Close
          </Button> 


              {/* <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  formikProps.handleSubmit();
                }}
                className="shadow-md"
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#4CAF50",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Add Payment
              </button> */}
            </div>
          </>
        )}
      </Formik>
    </div>
  );

  return (
    <>
      <div className="flex flex-wrap mb-7">
        <div className="text-xl font-medium text-default-900 flex-1">
          Ref # {booking?.refNo}
        </div>
        <div className="flex-none">
          <Breadcrumbs>
            <BreadcrumbItem
              onClick={() =>
                navigate(
                  global?.roleId == RoleEnum.Super_Admin
                    ? "/Booking"
                    : "/app/clubbookings"
                )
              }
            >
              Bookings
            </BreadcrumbItem>
            <BreadcrumbItem> Detail</BreadcrumbItem>
          </Breadcrumbs>
        </div>
      </div>

      <div className="app-height flex gap-6 relative overflow-hidden">
        {isDesktop && showSidebar && (
          <div
            className=" bg-background/60 backdrop-filter
         backdrop-blur-sm absolute w-full flex-1 inset-0 z-[99] rounded-md"
            onClick={() => setShowSidebar(false)}
          ></div>
        )}
        <div
          style={{ width: "350px" }}
          className={cn("transition-all duration-150 flex-none  ", {
            "absolute h-full top-0 md:w-[260px] w-[200px] z-[999]": isDesktop,
            "flex-none min-w-[260px]": !isDesktop,
            "left-0": isDesktop && showSidebar,
            "-left-full": isDesktop && !showSidebar,
          })}
        >
          <Card className=" h-full overflow-y-auto no-scrollbar">
            <CardHeader className="stickyx top-0 mb-0 bg-card z-50">
              <p className="font-bold text-left text-xl">
                {booking?.club?.name}
              </p>
              <p className="text-left text-xs">
                <Icon
                  icon={"heroicons:map"}
                  className={cn(
                    "w-4 h-4 float-left text-default-600 group-hover:text-primary",
                    ""
                  )}
                />
                {"  "}
                &nbsp; {booking?.club?.address}
              </p>
            </CardHeader>
            <CardContent className="h-full p-2">
              <BookingDetailSidebar
                setShowAddPayment={setShowAddPayment}
                booking={booking}
                getBookingDetails={getBookingDetails}
                onAdddNote={(param) => {
                  alert(param);
                }}
              />
            </CardContent>
          </Card>
        </div>

        <Card className="flex-1 overflow-x-auto">
          <BooingFurthurDetails
            booking={booking}
            openSheet={handleEditSheetOpen}
            handleSidebar={() => setShowSidebar(true)}
          />
        </Card>
      </div>
      <DialogModal
        title={"Add Payment"}
        open={showAddPayment}
        setOpen={setShowAddPayment}
        Child={_renderAddPayment}
        
      />
    </>
  );
};

export default BooingDetails;
