import React from "react";
import { Icon } from "@iconify/react";
import { Button } from "../../ui/button";

export default function ActionButton({ icon, onClick }) {
  return (
    <Button
      size="icon"
      variant="outline"
      className=" h-7 w-7"
      color="secondary"
      onClick={onClick}
    >
      <Icon icon={icon} className=" h-4 w-4  " />
    </Button>
  );
}
