import * as yup from "yup";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { Button } from "../../../../components/ui/button";
import Common from "../../../../helper/common";
import {
    FormikInput, FormikSearchSelect
} from "../../../../components/formik-components";
import { respEnum } from "../../../../constants/appConstant";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../../../components/ui/dialog";
import { useNavigate } from "react-router-dom";

const FormikInitialValues = {};
const formikValidation = yup.object({
    name: yup.string().required("Name is required"),
    stateId: yup.number().required("State is required")
});

const AddCities = ({ id, onClose, open, setOpen }) => {
    const formikRef = useRef();
    const dialogRef = useRef();
    const [State, setState] = useState([]);
    const [Country, setCountry] = useState([]);
    const navigate = useNavigate();

    const [isFormikInitialized, setIsFormikInitialized] = useState(false);

    useEffect(() => {
        if (open) {
            fetchData();
        } else {
            if (State?.length) {
                setState([]);
            }
        }
        setIsFormikInitialized(open);
        
    }, [open]);

    useEffect(() => {
        if (id) {
            getDataInfo();
        }
    }, [id]);

    const fetchData = async () => {
        getCountries();
      await  Common.delay();
      //  getStates();
    };

    const getCountries = async () => {
        return new Promise(async (res) => {
            const response = await Common.ApiService.getInstance().request(
                "GetCountries"
            );
            const data = [
                { id: null, name: "Select Country" },
                ...(response?.data ?? []),
            ];

            setCountry(data);
        });
    };

    const getStates = async (countryId) => {
        debugger
        return new Promise(async (res) => {
            const response = await Common.ApiService.getInstance().request(
                `GetStates?countryId=${countryId}`
            );
            const data = [
                { id: null, name: "Select State" },
                ...(response?.data ?? []),
            ];

            setState(data);
        });
    };

    const onSubmitData = async (d, action) => {
        debugger

        let data = {
            ...d,
            id,
        };
        let response = await Common.ApiService.getInstance().request(
            "AddUpdateCity",
            data,
            "POST"
        );
        action.setSubmitting(false);
        if (response.status === respEnum.Success) {
            onClose(response.data);
            setOpen(false);
            if (!onClose) {
                navigate("/app/Cities", { replace: true });
            }
        }
    };

    const getDataInfo = async () => {
        debugger
        let response = await Common.ApiService.getInstance().request(
            `GetCities?id=${id}`
        );
        if (response.status === respEnum.Success && response.data.length > 0) {
            var data = response.data[0];
            getStates(data.countryId);
            formikRef.current.setValues(data);
        }
    };

  

    return (
        <div>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger asChild>
                    {/*<Button*/}
                    {/*    style={{ marginTop: "-15px" }}*/}
                    {/*    size="icon"*/}
                    {/*    variant="ghost"*/}
                    {/*    className="group p-1 "*/}
                    {/*>*/}
                    {/*    <Icon icon="heroicons:plus-20-solid" className=" h-6 w-6 " />*/}
                    {/*</Button>*/}
                    <Button
                        //onClick={() => setOpen(true)}
                        size="sm"
                        variant="primary"
                        className="flex items-center gap-2"
                    >
                        <Icon icon="heroicons:plus-20-solid" className="h-4 w-4" />
                        Add City
                    </Button>
                </DialogTrigger>
                <DialogContent ref={dialogRef}>
                    <DialogHeader>
                        <DialogTitle className="text-base font-medium ">
                            {!id ? "Add" : "Update"} City
                        </DialogTitle>
                    </DialogHeader>

                    <div className="text-sm text-default-500  space-y-2">
                        <Formik
                            innerRef={formikRef}
                            onSubmit={onSubmitData}
                            initialValues={FormikInitialValues}
                            validationSchema={formikValidation}
                            validateOnChange={false}
                            validateOnBlur={false}
                        >
                            {(formikProps) => (
                                <div className="md:grid md:grid-cols-1 gap-6 mt-6 space-y-6 md:space-y-0">
                                    <div className="col-span-1">
                                        <div className="md:grid md:grid-cols-1  gap-6 mt-6 space-y-6 md:space-y-0">
                                            <div className="flex flex-col gap-3">
                                                <FormikSearchSelect
                                                    formikProps={formikProps}
                                                    name={"countryId"}
                                                    placeholder={"Select Country"}
                                                    label={"Country"}
                                                    data={Country}
                                                    onChange={(val) => {
                                                        
                                                        formikProps.setFieldValue('stateId', null);
                                                        setState([{ id: null, name: "Select State" }]);

                                                        if (val) {
                                                            getStates(val);
                                                        }
                                                    }}
                                                />

                                            </div>
                                            <div className="flex flex-col gap-3">
                                                <FormikSearchSelect
                                                    formikProps={formikProps}
                                                    name={"stateId"}
                                                    placeholder={"Select State"}
                                                    label={"State"}
                                                    data={State}
                                                />
                                            </div>
                                           
                                            <div className="flex flex-col gap-2">
                                                <FormikInput
                                                    formikProps={formikProps}
                                                    name={"name"}
                                                    label={"Name"}
                                                    placeholder={"Name"}
                                                    maxLength="20"
                                                />
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Formik>
                    </div>
                    <DialogFooter className="mt-8 gap-2">
                        <DialogClose asChild>
                            <Button type="button" variant="outline">
                                Close
                            </Button>
                        </DialogClose>

                        {isFormikInitialized && formikRef.current && (
                            <Button
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    formikRef.current?.handleSubmit();
                                }}
                            >
                                {formikRef.current.isSubmitting
                                    ? "Saving..."
                                    : !id
                                        ? "Add"
                                        : "Update"}
                            </Button>
                        )}
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AddCities;
