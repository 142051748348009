import "./App.css";
import "./assets/scss/globals.scss";
import "./assets/scss/theme.scss";
import { DefaultSpinner, LoaderContainer } from "react-global-loader";
import { ThemeProvider } from "./contexts/themeContext";
import { AuthProvider } from "./contexts/authContext";
import { cn } from "./helper/utilityHelper";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { Toaster } from "react-hot-toast";
import { Toast, ToastProvider } from "./components/ui/toast";
import { ReactToaster } from "./components/ui/toaster";
import LayoutLoader from "./components/layout-loader";

function App() {
  return (
    <AuthProvider>
      <ThemeProvider>
      
        <div className={cn("dash-tail-app theme-light")}>
          <LoaderContainer opacity={0.7} backgroundColor="#fff">
           <LayoutLoader/>
          </LoaderContainer>

          <RouterProvider router={router} />
          <Toaster />
          <ReactToaster/>

         
        </div>
      
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
