import React from "react";
import { Button } from "../../components/ui/button";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

export default function SportQuestions() {
  const navigate = useNavigate();
  const _navigateToAdd = () => navigate("/app/addQuestion");
  return (
    <div>
      <Button
        className="h-8 text-xs"
        size="xs"
        variant=""
        onClick={_navigateToAdd.bind(this, true)}
      >
        <span className="text-xl mr-1">
          <Icon icon="icon-park-outline:plus" />
        </span>
        Add Questions
      </Button>
    </div>
  );
}
