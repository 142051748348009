import Select from "react-select";
import { Label } from "../ui/label";
import { useMemo } from "react";
import './index.css'

export default function AppSearchSelect({
  value,
  onChange = () => {},
  placeholder,
  data = [],
  labelKey = "name",
  valueKey = "id",
  label,
  labelButton,
  style,
  className,
}) {
  // Prepare options for react-select
  const dataList = useMemo(
    () =>
      data?.map((item) => ({
        value: item[valueKey],
        label: item[labelKey],
      })),
    [data]
  );

  // Find the selected value in the correct format
  const selectedValue = dataList.find((option) => option.value === value);

    return (
    
    <div className={className}>
      <div className="flex justify-between">
        {label && (
          <Label className="mb-2" htmlFor="inputId">
            {label}
          </Label>
        )}
        {labelButton && labelButton()}
      </div>
      <Select
        className="react-select"
        placeholder={placeholder}
        value={selectedValue} // Set selected value correctly
        onChange={(val) => onChange(val.value)} // Correctly pass the selected value
        options={dataList}
        styles={{
          option: (provided) => ({
            ...provided,
            fontSize: "14px", // Option styling
          }),
        }}
      />
    </div>
  
  );
}
