import React from "react";
import { Badge } from "./badge";
const getStatusColor = (value) => {
  switch (value) {
    case "isActive":
      return "success";
    case "inActive":
      return "warning";
    case "complete":
      return "info";
    default:
      return "default";
  }
};
const StatusTag = ({ text, tagcolor }) => {
  return (
    <Badge variant="soft" color={tagcolor} className=" capitalize">
      {text}
    </Badge>
  );
};
export { getStatusColor, StatusTag };
