import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import TypeFeaturesCard from "../components/typeFeatureCard";
import TypeformatsCard from "../components/typeFormatCard";

import * as yup from "yup";
import { Button } from "../../../components/ui/button";
import Card from "../../../components/ui/card-snippet";
import { useLocation, useNavigate } from "react-router-dom";
import {
    SportFeatureTypeEnum,
    IntMaxnoofplayers,
    IntMin,
    IntMinLevel,
    IntSportMaxLevel,
    IntMinPlatFormFees,
    IntMaxPlatFormFees,
    respEnum,
    duration,
} from "../../../constants/appConstant";
import {
    FormikInput,
    FormikSingleFileUpload,
    FormikSelect,
    FormikSwitch,
} from "../../../components/formik-components";
import Common from "../../../helper/common";

const formValidations = yup.object({
    name: yup.string().required("Name is Required"),

    minLevel: yup
        .number()
        .min(IntMin)
        .max(IntSportMaxLevel)
        .required("Min Level is Required"),

    maxLevel: yup
        .number()
        .min(IntMinLevel)
        .max(IntSportMaxLevel)
        .required("Max Level is Required"),

    platformFee: yup
        .number()
        .min(IntMinLevel)
        .max(IntMaxPlatFormFees)
        .required("Platform Fees is Required"),
});

export default function AddSport() {
    const navigate = useNavigate();
    const location = useLocation();
    const formikRef = useRef();
    const featureFormikRef = useRef();
    const formatsFormikRef = useRef();

    const [id, setId] = useState(null);
    const [addedFeatures, setAddedFeatures] = useState([]);
    const [addedFormats, setAddedFormats] = useState([]);

    const formInitialValue = {
        slotDuration: 30,
        minLevel: 0,
        maxLevel: 1,
        platformFee: 10,
        features: [],
        formats: [],
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const fetchedId = params.get("id");
        if (fetchedId) {
            setId(fetchedId);
            GetSportsDetails(fetchedId);
        }
    }, []);

    const GetSportsDetails = async (id) => {
        let response = await Common.ApiService.getInstance().request(
            `GetSports?id=${id}`
        );

        let d = response?.data?.[0];

        //if (d && formikRef.current) {
        //   // formikRef.current.setFieldValue("sportId", d.sportId);
        //   // formikRef.current.setFieldValue("venueType", d.venueType);
        //  //  onVenueTypeChange(d.venueType, formikRef.current);
        //   // onSportChange(d.sportId, formikRef.current);
        //    await Common.delay();
        //}

        if (formikRef.current) {
            formikRef.current.setValues(d);
            setAddedFeatures(d.sportFeatures);
            setAddedFormats(d.sportFormats);
        }

        //  updatePrizes(d.prizes);
    };

    const onSubmitData = async (d, action) => {
        debugger;

        if (d.minLevel > d.maxLevel) {
            Common.showToast("Max Level should be grater than Min Level");
            return;
        }
        if (!d.slotDuration) {
            Common.showToast("Select Duration");
            return;
        }

        if (!d.files && !d.icon) {
            Common.showToast("Select Icon");
            return;
        }
        if (d.files && d.files.filter((item) => !item.imageUrl).length) {
            d.icon = (await Common.Utility.handleFileUpload(d.files))[0].photo;
            d.files = null;
        }

        // Validate addedFeatures to ensure each type has at least one field
        const types = [
            SportFeatureTypeEnum.Type,
            SportFeatureTypeEnum.Feature,
            SportFeatureTypeEnum.No_of_Players,
        ];

        for (let type of types) {
            const featureForType = addedFeatures.filter((f) => f.type === type);

            if (
                featureForType.length === 0 ||
                !featureForType.some((f) => f.name || f.noofplayers)
            ) {
                Common.showToast(`Each feature must have at least one value.`);
                return;
            }
        }

        if (addedFormats.length <= 0) {
            Common.showToast("format must have a value.");
            return;
        }

        let sportFeatures = addedFeatures.map((d) => ({
            ...d,
            noofplayers: d.noofplayers ? +d.noofplayers : null,
        }));

        let sportFormats = addedFormats.map((d) => ({
            ...d,
            value: d.value ? +d.value : null,
        }));

        let data = {
            ...d,
            id,
            minLevel: +d.minLevel,
            maxLevel: +d.maxLevel,
            slotDuration: +d.slotDuration,
            sportFeatures: sportFeatures,
            sportFormats: sportFormats,
        };
        debugger;
        let response = await Common.ApiService.getInstance().request(
            "AddUpdateSport",
            data,
            "POST"
        );
        action.setSubmitting(false);
        if (response.status == respEnum.Success) {
            Common.SweetAlert.alert(response.message);
            setTimeout(() => {
                navigate("/app/sports");
            }, 1000);
        }
    };

    const getCanSubmit = ({ type, name, noofplayers }) =>
        (type &&
            type !== SportFeatureTypeEnum.No_of_Players &&
            name &&
            name != "") ||
        (type &&
            type === SportFeatureTypeEnum.No_of_Players &&
            name &&
            name != "" &&
            noofplayers &&
            noofplayers != "" &&
            noofplayers > 0);

    const handleFeatureSubmit = (featuresFormikProps) => {
        const { isEdit, currentFeature, index } = featuresFormikProps.values;

        const newFeature = isEdit  ? { ...currentFeature, ...featuresFormikProps.values }
            : featuresFormikProps.values;

        // If it's in edit mode and an index is provided, update the feature
        if (isEdit && index != null) {
            let type = newFeature.type;

            var otherTypeFeatures = addedFeatures.filter((item) => item.type !== type);
            var sameTypeFeatures = addedFeatures
                .filter((item) => item.type === type)
                .map((item, i) => (i === index ? newFeature : item));

            setAddedFeatures([...otherTypeFeatures, ...sameTypeFeatures]);

        } else {
            setAddedFeatures((prevFeatures) => [...prevFeatures, newFeature]);
        }

        featuresFormikProps.resetForm();

        featuresFormikProps.setFieldValue("isEdit", false);
    };

    const handleRemoveFeature = (feature) => {
        setAddedFeatures((prevFeatures) =>
            prevFeatures.filter((entry) => entry !== feature)
        );
    };

    const getCanSubmitFormat = ({ name, value }) =>
        name && name != "" && value && value >= 0;

    const handleFormatSubmit = (formatsFormikProps) => {
        const { isEdit, currentFormat, index } = formatsFormikProps.values;

        const newFormat = isEdit ? { ...currentFormat, ...formatsFormikProps.values }
            : formatsFormikProps.values;

        if (isEdit && index != null) {
            setAddedFormats(addedFormats.map((item, i) => (i === index ? newFormat : item)));
        } else {
            setAddedFormats((prevFormat) => [...prevFormat, newFormat]);
        }

        formatsFormikProps.resetForm();

        formatsFormikProps.setFieldValue("isEdit", false);
    };

    const handleRemoveFormat = (format) => {
        setAddedFormats((prevFormat) =>
            prevFormat.filter((entry) => entry !== format)
        );
    };


    return (
        <Formik
            initialValues={formInitialValue}
            validationSchema={formValidations}
            innerRef={formikRef}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={onSubmitData}
        >
            {(formikProps) => {
                formikRef.current = formikProps;
                return (
                    <>
                        <div className="space-y-6 mb-5">
                            <Card title="Create Sports">
                                <div className="md:grid md:grid-cols-12 gap-6">
                                    <div className="flex flex-col col-span-3 gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"name"}
                                            placeholder={"Sports Name"}
                                            label={"Sports Name"}
                                            maxLength="20"
                                        />
                                    </div>

                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"minLevel"}
                                            placeholder={"Min Level"}
                                            label={"Min Level"}
                                            type={"number"}
                                            max={IntSportMaxLevel}
                                            min={IntMin}
                                            onChange={(value) => {
                                                if (value < IntMin && value != "") {
                                                    formikProps.setFieldValue("minLevel", IntMin);
                                                } else if (value > IntSportMaxLevel) {
                                                    Common.showToast(`Min Level cannot be greater than ${IntSportMaxLevel}`);
                                                    formikProps.setFieldValue("minLevel", "");
                                                } else {
                                                    formikProps.setFieldValue("minLevel", value);
                                                }
                                            }}
                                        />
                                    </div>

                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"maxLevel"}
                                            placeholder={"Max Level"}
                                            label={"Max Level"}
                                            type={"number"}
                                            max={IntSportMaxLevel}
                                            min={IntMinLevel}
                                            onChange={(value) => {
                                                if (value < IntMinLevel && value != "") {
                                                    formikProps.setFieldValue("maxLevel", IntMinLevel);
                                                } else if (value > IntSportMaxLevel) {
                                                    Common.showToast(`Max Level cannot be greater than ${IntSportMaxLevel}`);
                                                    formikProps.setFieldValue("maxLevel", "");
                                                } else {
                                                    formikProps.setFieldValue("maxLevel", value);
                                                }
                                            }}
                                        />
                                    </div>

                                    <div className="flex flex-col col-span-2 gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"platformFee"}
                                            placeholder={"Platform Fees"}
                                            label={"Platform Fees"}
                                            type={"number"}
                                            max={IntMaxPlatFormFees}
                                            min={IntMinLevel}
                                            onChange={(value) => {
                                                if (value < IntMinLevel && value != "") {
                                                    formikProps.setFieldValue(
                                                        "platformFee",
                                                        IntMinPlatFormFees
                                                    );
                                                } else if (value > IntMaxPlatFormFees) {
                                                    Common.showToast(`PlatForm Fees cannot be greater than ${IntMaxPlatFormFees}`);
                                                    formikProps.setFieldValue("platformFee", "");
                                                } else {
                                                    formikProps.setFieldValue("platformFee", value);
                                                }
                                            }}
                                        />
                                    </div>

                                    <div className="flex flex-col col-span-3 gap-3">
                                        <FormikSelect
                                            formikProps={formikProps}
                                            name={"slotDuration"}
                                            placeholder={"Select Duration"}
                                            label={"Slot Duration"}
                                            data={duration}
                                        />
                                    </div>
                                </div>

                                <div className="md:grid md:grid-cols-12 gap-6 mt-10">
                                    <div className="flex flex-col col-span-5 gap-6">
                                        <div className="flex flex-col gap-2">
                                            <FormikSingleFileUpload
                                                formikProps={formikProps}
                                                uploadMainTxt={"Upload Icon"}
                                                uploadTxt={"Max size 2mb"}
                                                name={"icon"}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col col-span-6 gap-6">
                                        <div className="flex flex-col col-span-3 gap-2">
                                            <FormikInput
                                                formikProps={formikProps}
                                                name={"desc"}
                                                placeholder={"Description"}
                                                label={"Description"}
                                            />
                                        </div>

                                        <div className="md:grid md:grid-cols-3 gap-6">
                                            <FormikSwitch
                                                formikProps={formikProps}
                                                name={"isOnMobile"}
                                                label={"On Mobile"}
                                            />
                                            <FormikSwitch
                                                formikProps={formikProps}
                                                name={"isactive"}
                                                label={"Active"}
                                            />
                                            <FormikSwitch
                                                formikProps={formikProps}
                                                name={"isPublicBookingAllowed"}
                                                label={"Public Booking"}
                                            />
                                        </div>

                                        <div className="md:grid md:grid-cols-3 gap-6">
                                            <FormikSwitch
                                                formikProps={formikProps}
                                                name={"isTournament"}
                                                label={"Tournament"}
                                            />
                                            <FormikSwitch
                                                formikProps={formikProps}
                                                name={"isLeague"}
                                                label={"League"}
                                            />
                                            <FormikSwitch
                                                formikProps={formikProps}
                                                name={"isLeaderBoard"}
                                                label={"LeaderBoard"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Card>

                            <div className="md:grid md:grid-cols-5 gap-6 mt-10">
                                {/* First Formik Section - Takes 2/3 of the width (col-md-8) */}
                                <div className="col-span-3">
                                    {/* Occupies 2 out of 3 columns */}
                                    <Formik
                                        initialValues={{
                                            type: null,
                                            name: "",
                                            noofplayers: "",
                                            isEdit: false,
                                            currentFeature: {},
                                        }}
                                        innerRef={featureFormikRef}
                                    >
                                        {(featuresFormikProps) => (
                                            <>
                                                <Card>
                                                    <div className="md:grid md:grid-cols-7 gap-6">
                                                        <div className="flex flex-col col-span-2 gap-2">
                                                            <FormikSelect
                                                                formikProps={featuresFormikProps}
                                                                name="type"
                                                                data={[
                                                                    { id: null, name: "Select" },
                                                                    ...Common.Utility.enumToArray(
                                                                        SportFeatureTypeEnum
                                                                    ),
                                                                ]}
                                                                placeholder="Type"
                                                                label="Type"
                                                                disabled={featuresFormikProps.values.isEdit}
                                                                onChange={(val) => {
                                                                    featuresFormikProps.setFieldValue(
                                                                        "noofplayers",
                                                                        ""
                                                                    );
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="flex flex-col col-span-2 gap-2">
                                                            <FormikInput
                                                                formikProps={featuresFormikProps}
                                                                name="name"
                                                                placeholder="Name"
                                                                type="text"
                                                                label="Name"
                                                                maxLength = "20"
                                                            />
                                                        </div>

                                                        <div className="flex flex-col col-span-2 gap-2">
                                                            <FormikInput
                                                                formikProps={featuresFormikProps}
                                                                name={"noofplayers"}
                                                                placeholder={"No Of Players"}
                                                                label={"No Of Players"}
                                                                type={"number"}
                                                                max={IntMaxnoofplayers}
                                                                min={IntMinLevel}
                                                                disabled={
                                                                    featuresFormikProps.values.type !==
                                                                    SportFeatureTypeEnum.No_of_Players
                                                                }
                                                                onChange={(value) => {
                                                                    if (value < IntMinLevel && value !== "") {
                                                                        featuresFormikProps.setFieldValue(
                                                                            "noofplayers",
                                                                            IntMinLevel
                                                                        );
                                                                    }
                                                                    if (value > IntMaxnoofplayers) {
                                                                        Common.showToast(`No of players cannot be greater than ${IntMaxnoofplayers}`, "Error");
                                                                        featuresFormikProps.setFieldValue(
                                                                            "noofplayers",
                                                                            ""
                                                                        );
                                                                    } else {
                                                                        featuresFormikProps.setFieldValue(
                                                                            "noofplayers",
                                                                            value
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="flex flex-col col-span-1 gap-2">
                                                            <Button
                                                                disabled={
                                                                    !getCanSubmit(featuresFormikProps.values)
                                                                }
                                                                onClick={() =>
                                                                    handleFeatureSubmit(featuresFormikProps)
                                                                }
                                                                className="mt-7 btn-sm"
                                                            >
                                                                {featuresFormikProps.values.isEdit
                                                                    ? "Update"
                                                                    : "Add"}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </>
                                        )}
                                    </Formik>
                                </div>

                                {/* Second Formik Section - Takes 1/3 of the width (col-md-4) */}
                                <div className="col-span-2">
                                    {" "}
                                    {/* Occupies 1 out of 3 columns */}
                                    <Formik
                                        initialValues={{
                                            name: "",
                                            value: "",
                                            isEdit: false,
                                            currentFormat: {},
                                        }}
                                        innerRef={formatsFormikRef}
                                    >
                                        {(formatsFormikProps) => (
                                            <>
                                                <Card>
                                                    <div className="md:grid md:grid-cols-5 gap-6">
                                                        <div className="flex flex-col col-span-2 gap-2">
                                                            <FormikInput
                                                                formikProps={formatsFormikProps}
                                                                name="name"
                                                                placeholder="Name"
                                                                type="text"
                                                                label="Name"
                                                                maxLength="20"
                                                            />
                                                        </div>

                                                        <div className="flex flex-col col-span-2 gap-2">
                                                            <FormikInput
                                                                formikProps={formatsFormikProps}
                                                                name={"value"}
                                                                placeholder={"Value"}
                                                                label={"Value"}
                                                                type={"number"}
                                                                max={500}
                                                                min={IntMinLevel}
                                                                onChange={(value) => {
                                                                    if (value < IntMinLevel && value !== "") {
                                                                        formatsFormikProps.setFieldValue(
                                                                            "value",
                                                                            IntMinLevel
                                                                        );
                                                                    }
                                                                    if (value > 360) {
                                                                        Common.showToast(`Value Cannot be greater than 360`, "Error");
                                                                        formatsFormikProps.setFieldValue(
                                                                            "value",
                                                                            ""
                                                                        );
                                                                    } else {
                                                                        formatsFormikProps.setFieldValue(
                                                                            "value",
                                                                            value
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="flex flex-col col-span-1 gap-2">
                                                            <Button
                                                                disabled={
                                                                    !getCanSubmitFormat(formatsFormikProps.values)
                                                                }
                                                                onClick={() =>
                                                                    handleFormatSubmit(formatsFormikProps)
                                                                }
                                                                className="mt-7 btn-sm"
                                                            >
                                                                {formatsFormikProps.values.isEdit
                                                                    ? "Update"
                                                                    : "Add"}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </>
                                        )}
                                    </Formik>
                                </div>

                                <div className="md:grid md:grid-cols-3 col-span-3 gap-6">
                                    <TypeFeaturesCard
                                        featuresFormikProps={featureFormikRef.current}
                                        formikProps={featureFormikRef.current}
                                        data={addedFeatures.filter(
                                            (item) => item.type === SportFeatureTypeEnum.Type
                                        )}
                                        name="features"
                                        title="Types"
                                        onRemove={handleRemoveFeature}
                                    />

                                    <TypeFeaturesCard
                                        featuresFormikProps={featureFormikRef.current}
                                        formikProps={featureFormikRef.current}
                                        data={addedFeatures.filter(
                                            (item) => item.type === SportFeatureTypeEnum.Feature
                                        )}
                                        name="features"
                                        title="Features"
                                        onRemove={handleRemoveFeature}
                                    />

                                    <TypeFeaturesCard
                                        featuresFormikProps={featureFormikRef.current}
                                        formikProps={featureFormikRef.current}
                                        data={addedFeatures.filter(
                                            (item) => item.type === SportFeatureTypeEnum.No_of_Players
                                        )}
                                        name="features"
                                        title="No Of Players"
                                        onRemove={handleRemoveFeature}
                                    />
                                </div>

                                <div className="md:grid md:grid-cols-1 col-span-2 gap-6">
                                    <TypeformatsCard
                                        formatsFormikProps={formatsFormikRef.current}
                                        formikProps={formatsFormikRef.current}
                                        data={addedFormats}
                                        nameKey="name"
                                        valueKey="value"
                                        title="Formats"
                                        onRemove={handleRemoveFormat}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col items-end mt-5">
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    formikProps.handleSubmit();
                                }}
                                disabled={formikProps.isSubmitting}
                                type="submit"
                                className="mt-7 btn-sm"
                            >
                                {formikProps.isSubmitting ? "Saving..." : "Save"}
                            </Button>
                        </div>
                    </>
                );
            }}
        </Formik>
    );
}
