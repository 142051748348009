import React from "react";
import Select from "react-select";
import ErrorText from "./error-text";
import { Label } from "../ui/label";

export default function FormikMultiSelect({
  styles,
  animatedComponents,
  formikProps,
  onChange = () => {},
  data = [],
  label,
  labelButton,
  name,
}) {
  return (
    <div>
      <div className="flex justify-between">
        {label && (
          <Label className="mb-3" htmlFor="inputId">
            {label}
          </Label>
        )}
        {labelButton && labelButton()}
      </div>
      <Select
        isClearable={false}
        closeMenuOnSelect={false}
        components={animatedComponents}
        value={formikProps.values[name]}
        onChange={(value) => {
          formikProps.setFieldValue(name, value);
          if (onChange) {
            onChange(value);
          }
        }}
        isMulti
        options={data}
        styles={styles}
        className="react-select"
        classNamePrefix="select"
      />

      <ErrorText name={name} formikProps={formikProps} />
    </div>
  );
}
