import { useEffect, useState } from "react";
import { loader } from "react-global-loader";
import { Icon } from "@iconify/react";
import { Button } from "../../components/ui/button";
import Common from "../../helper/common";
import AdvanceTable from "../../components/advanceTable";
import { useNavigate } from "react-router-dom";

const Sports = () => {
  const [sports, setSports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [refId, setRefId] = useState();

  const navigate = useNavigate();

  const getSports = async () => {
    setLoading(true);
    const response = await Common.ApiService.getInstance().request("GetSports");
    let data = response?.data?.map((item) => ({
      ...item,
      isactive: item.isactive ? "Yes" : "No",
      isOnMobile: item.isOnMobile ? "Yes" : "No",
    }));

    setSports(data);
    setLoading(false);
  };

  useEffect(() => {
    //loader.hide();
    getSports();
  }, []);

  useEffect(() => {
    if (!open && refId) {
      setRefId();
    }
  }, [open]);


  const _onIsOnMobileChange = (obj) => {
    setSports(
      sports.map((item) =>
        item.id == obj.id ? { ...item, isOnMobile: !item.isOnMobile } : item
      )
    );
    };

    const _handleDelete = async (id) => {
        const isConfirmed = await Common.SweetAlert.confirmation(
            "Are you sure you want to delete this sports?",
            "Delete Sports"
        );
        if (isConfirmed) {
            let resp = await Common.ApiService.getInstance().request(
                `DeleteSport?id=${id}`,
                {},
                "Delete"
            );
            Common.SweetAlert.alert(resp.message, "success");
            setTimeout(() => {
                getSports(1);
            }, 500);
        }
    };
  const _thead = [
    { title: "Id", type: "number", isVisible: false, key: "id" },
    { title: "Sport Name", type: "text", isVisible: true, key: "name" },
    { title: "Icon", type: "image", isVisible: true, key: "icon" },
    { title: "Description", type: "text", isVisible: false, key: "desc" },
    { title: "Is On Mobile", type: "text", isVisible: true, key: "isOnMobile", onChanged: _onIsOnMobileChange, },
    { title: "Is Active", type: "text", isVisible: true, key: "isactive" },
  ];

  //const _onEdit = (val) => {
  //    setOpen(true);
  //    setRefId(val.id);
  //};

  const actions = [
    {
      type: "edit",
      onClick: (val) => {
        console.log(val);
        navigate(`/app/addSport?id=${val.id}`, {});
      },
      // onClick: _onEdit,
    },
    {
        type: "delete",
        onClick: (val) => _handleDelete(val.id),

    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  //const AddUpdateSport = () => (
  //  <AddUpdateModal
  //    setOpen={setOpen}
  //    size={"600px"}
  //    Component={() => (
  //      <AddSport
  //        id={refId}
  //        onClose={(val) => {
  //          setOpen(false);
  //          val && getSports();
  //        }}
  //      />
  //    )}
  //    title={refId ? "Edit Sport" : "Add Sport"}
  //    open={open}
  //  />
  //);

  //const _addBtn = () => (
  //  <Button onClick={() => setOpen(true)}>{"Add New"}</Button>
  //  );

  const _addUpdateTrigger = () => (
    <Button size="icon" variant="outline" color="secondary" className="h-7 w-7">
      <Icon icon="heroicons:pencil" className="h-4 w-4" />
    </Button>
  );


  const _addBtn = () => (
    <Button onClick={() => navigate("/app/addSport")}>
      <span className="text-xl mr-1">
        <Icon icon="icon-park-outline:plus" />
      </span>
      Add New
    </Button>
  );

  return (
    <>
      <AdvanceTable
        title={"Sports"}
        columns={_thead.filter((item) => item.isVisible)}
        data={{ data: sports }}
        addButton={_addBtn}
        showPagination={false}
        showAddButton
        actionButtons={actions}
        updateTrigger={_addUpdateTrigger}
      />
      {/* <AddUpdateSport />*/}
    </>
  );
};

export default Sports;
