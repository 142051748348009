import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import moment from "moment";
import SubmitFormInSheet from "./components/addBooking";

import {
  AppSelect,
  AppSearchSelect,
  AppInput,
} from "../../components/appComponents";
import "../../App.css";
import { Button } from "../../components/ui/button";
import AdvanceTable from "../../components/advanceTable";
import {
  BookingModeEnum,
  BookingPaymentMethodEnum,
  BookingStatusEnum,
  BookingTypeEnum,
  MatchTypeEnum,
  PaymentStatusEnum,
  ToCSharpFormat,
  ViewDateFormat,
} from "../../constants/appConstant";
import AddUpdateModal from "../../components/formComponents/addUpdateModal";
import { useNavigate } from "react-router-dom";
import Common from "../../helper/common";

const ClubBookings = () => {
  const [data, setData] = useState({});
  const [showAdd, setShowAdd] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [customerId, setCustomerId] = useState(null);
  const [courtId, setCourtId] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [bookingStatus, setBookingStatus] = useState(null);
  const [bookingMode, setBookingMode] = useState(null);
  const [fromDateTime, setFromDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const [matchType, setMatchType] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [bookingType, setBookingType] = useState(null);
  const [courtList, setCourtList] = useState([]);
  const navigate = useNavigate();

  const _bookingStatuses = [
    { id: null, name: "Select" },
    { id: BookingStatusEnum.Booked, name: "Booked" },
    { id: BookingStatusEnum.Cancelled_By_Club, name: "Cancelled By Club" },
    {
      id: BookingStatusEnum.Cancelled_Due_To_Lack_Of_Player,
      name: "Cancelled Due To Lack Of Player",
    },
  ];

  useEffect(() => {
    (async () => {
      getData(1);
      await Common.delay();
      getCustomers();
      await Common.delay();
      getCourts();
    })();
  }, [showAdd]);

  useEffect(() => {
    getData(1);
  }, [
    customerId,
    paymentStatus,
    bookingStatus,
    courtId,
    bookingMode,
    fromDateTime,
    endDateTime,
    bookingType,
    matchType,
    paymentMethod,
    bookingType,
  ]);

  const getCustomers = async () => {
    return new Promise(async (res) => {
      const response = await Common.ApiService.getInstance().request(
        "ClubPlayersddl",
        {},
        "Get",
        false
      );
      const customers = [{ id: null, name: "All" }, ...(response?.data ?? [])];
      setCustomerList(customers);
    });
  };

  const getCourts = async () => {
    const response = await Common.ApiService.getInstance().request(
      "GetCourtddl",
      {},
      "Get",
      false
    );
    const courts = [{ id: null, name: "All" }, ...(response?.data ?? [])];
    setCourtList(courts);
  };

  const resetFilters = () => {
    setCustomerId(null);
    setCourtId(null);
    setPaymentStatus(null);
    setBookingStatus(null);
    setBookingMode(null);
    setFromDateTime("");
    setEndDateTime("");
    setMatchType(null);
    setPaymentMethod(null);
    setBookingType(null);
  };

  const getData = async (
    pageNumber,
    searchTerm = "",
    recordsToDisplay = null
  ) => {
    const response = await Common.ApiService.getInstance().request(
      "GetBookings",
      {
        pageNumber: pageNumber,
        q: searchTerm,
        isactive: true,
        currentDatetime: null, //"2024-07-05T11:22:16.639Z",
        isResultUploaded: null,
        isMine: null, //false
        fromDateTime: fromDateTime
          ? Common.moment(`${fromDateTime}`).format(ToCSharpFormat)
          : null,
        endDateTime: endDateTime
          ? Common.moment(`${endDateTime}`).format(ToCSharpFormat)
          : null,
        clubId: null,
        courtId,
        sportId: null,
        bookedById: customerId,
        bookingStatus,
        noofplayers: null,
        paymentStatus,
        recordsToDisplay,
        bookingMode,
        matchType,
        paymentMethod,
        bookingType,
      },
      "Post"
    );
    if (response?.data) {
      let obj = {
        ...response.data[0],
        data: response.data[0].data.map((item) => ({
          ...item,
          bookingDate: `${moment(item.bookingDate).format(ViewDateFormat)}`,
          paymentStatusId: item.paymentStatus,
          paymentStatus: Common.Utility.getKeyByValue(
            PaymentStatusEnum,
            item.paymentStatus
          ),

          bookingType: Common.Utility.getKeyByValue(
            BookingTypeEnum,
            item.bookingType
          ),
          bookingStatus: Common.Utility.getKeyByValue(
            BookingStatusEnum,
            item.bookingStatus
          ),
          bookingMode: Common.Utility.getKeyByValue(
            BookingModeEnum,
            item.bookingMode
          ),
        })),
      };

      setData(obj);
    }
  };

  const _addUpdateTrigger = () => (
    <Button size="icon" variant="outline" color="secondary" className="h-7 w-7">
      <Icon icon="heroicons:pencil" className="h-4 w-4" />
    </Button>
  );

  const actions = [
    {
      type: "view",
      onClick: (val) => {
        navigate(`/app/bookingDetails?id=${val.id}`);
      },
    },
  ];

  let columns = [
    // { title: "Ref #", type: "text", key: "refNo" },
    { title: "Booking Date", type: "text", key: "bookingDate" },
    { title: "Time", type: "text", key: "bookingDetail" },
    {
      title: "Charges",
      type: "number",
      key: "courtcharges",
      onClick: (param) => {
        console.log(param);
      },
    },
    { title: "Booked By", type: "text", key: "bookedBy" },
    // { title: "No of Players", type: "text", key: "noofplayers" },
    // { title: "Player Cell #", type: "text", key: "playerCellNo",  },
    { title: "Court", type: "text", key: "courtName" },
    {
      title: "Payment Status",
      type: "text",
      key: "paymentStatus",
      render: (record) =>
        Common.Utility.renderBadge(
          record.paymentStatusId,
          record.paymentStatus
        ),
    },

    { title: "Booking Mode", type: "text", key: "bookingMode" },
    { title: "Type", type: "text", key: "bookingType" },
    {
      title: "Status",
      type: "text",
      key: "bookingStatus",
      render: (record) => (
        <span
          className={`status-${
            record.bookingStatus ? record.bookingStatus.toLowerCase() : "-"
          }`}
        >
          {record.bookingStatus || "-"}
        </span>
      ),
    },

    // Add more headers as per your requirement
  ];

  const _addBtn = () => (
    <Button
      className="h-8 text-xs"
      size="xs"
      variant=""
      onClick={() => {
        setShowAdd(true);
      }}
    >
      <span className="text-xl mr-1">
        <Icon icon="icon-park-outline:plus" />
      </span>
      Add Booking
    </Button>
  );

  return (
    <>
      {/* <BookingsFilter /> */}
      {/* <AppSearchSectionSelect
        placeholder={"Select Club"}
        value={"jksc"}
        onChange={(val) => {}}
        data={[
          {
            type: "group",
            name: "Padel",
            items: [{ name: "JKSC", value: "jksc" }],
          },
          ,
          {
            type: "group",
            name: "Football",
            items: [{ name: "Club Padel", value: "cp" }],
          },
        ]}
      /> */}
      <AddUpdateModal
        open={showAdd}
        setOpen={setShowAdd}
        Component={(props) => (
          <SubmitFormInSheet {...props} onClose={() => setShowAdd(false)} />
        )}
        title={"Create Booking"}
        //size={"700px"}
      />

      <div className="md:grid md:grid-cols-6 gap-3">
        <AppSearchSelect
          value={customerId}
          onChange={setCustomerId}
          placeholder={"Select Customer"}
          label={"Customer"}
          data={customerList}
          className={"mb-3 col-span-1 "}
        />

        <AppSelect
          value={courtId}
          onChange={setCourtId}
          placeholder={"Select Court"}
          label={"Court"}
          data={courtList}
          className={"mb-3 col-span-1"}
        />

        <AppSelect
          value={paymentStatus}
          onChange={setPaymentStatus}
          placeholder="Select Payment Status"
          label="Payment Status"
          data={[
            { id: null, name: "Select" },
            ...Common.Utility.enumToArray(PaymentStatusEnum),
          ]}
          className="mb-3 col-span-1"
        />

        <AppSelect
          value={bookingStatus}
          onChange={setBookingStatus}
          placeholder="Select Status"
          label="Booking Status"
          data={_bookingStatuses}
          className="mb-3 col-span-1"
        />

        <AppSelect
          value={bookingMode}
          onChange={setBookingMode}
          placeholder="Select Booking Mode"
          label="Booking Mode"
          data={[
            { id: null, name: "Select" },
            ...Common.Utility.enumToArray(BookingModeEnum),
          ]}
          className="mb-3 col-span-1"
        />

        {/* <Button
  className="h-8 w-fit"
  style={{ marginTop: '22px'}}
  onClick={resetFilters}
>  Reset Filters
</Button> */}
        {/* Desktop Reset Button */}
        <Button
          className="h-8 w-fit hidden md:block"
          style={{ marginTop: "22px" }}
          onClick={resetFilters}
        >
          Reset Filters
        </Button>
      </div>

      <div className="md:grid md:grid-cols-6 gap-3">
        {/* <AppInput
          type={"date"}
          value={fromDateTime}
          onChange={setFromDateTime}
          label="From Date"
          className="mb-5 col-span-1"
        />

        <AppInput
          type={"date"}
          value={endDateTime}
          onChange={setEndDateTime}
          label="Till Date"
          className="mb-5 col-span-1"
        /> */}

        <AppInput
          type={"date"}
          value={fromDateTime}
          onChange={setFromDateTime}
          label="From Date"
          className="mb-3 col-span-1"
        />

        <AppInput
          type={"date"}
          value={endDateTime}
          onChange={(e) => {
            const selectedEndDate = e;
            if (selectedEndDate < fromDateTime) {
              Common.showToast("EndTime must be greater", "Error", "info");
              setEndDateTime("");
            } else {
              setEndDateTime(selectedEndDate);
            }
          }}
          label="Till Date"
          className="mb-3 col-span-1"
        />

        <AppSelect
          value={matchType}
          onChange={setMatchType}
          label="Match Type"
          data={[
            { id: null, name: "Select" },
            ...Common.Utility.enumToArray(MatchTypeEnum),
          ]}
          className="mb-3 col-span-1"
        />

        <AppSelect
          value={paymentMethod}
          onChange={setPaymentMethod}
          label="Payment Method"
          data={[
            { id: null, name: "Select" },
            ...Common.Utility.enumToArray(BookingPaymentMethodEnum),
          ]}
          className="mb-3 col-span-1"
        />

        <AppSelect
          value={bookingType}
          onChange={setBookingType}
          label="Booking Type"
          data={[
            { id: null, name: "Select" },
            ...Common.Utility.enumToArray(BookingTypeEnum),
          ]}
          className="mb-3 col-span-1"
        />

        <div className="md:hidden mt-3  mb-5">
          <Button className="w-full h-8" onClick={resetFilters}>
            Reset Filters
          </Button>
        </div>
      </div>

      {data?.data && (
        <AdvanceTable
          title={"Bookings"}
          columns={columns}
          data={data}
          getData={getData}
          addButton={_addBtn}
          showPagination
          showAddButton
          actionButtons={actions}
          updateTrigger={_addUpdateTrigger}
        />
      )}
    </>
  );
};

export default ClubBookings;
