import React, { useState } from "react";
import { Button } from "../../components/ui/button";
import { Icon } from "@iconify/react";
import AddUpdateModal from "../../components/formComponents/addUpdateModal";
import AddPromoCode from "./components/addPromoCode";

export default function PromoCode() {
  const [addVisible, setAddVisible] = useState(false);
  return (
    <div>
      <Button
        className="h-8 text-xs"
        size="xs"
        variant=""
        onClick={setAddVisible.bind(this, true)}
      >
        <span className="text-xl mr-1">
          <Icon icon="icon-park-outline:plus" />
        </span>
        Add Promo Code
      </Button>

      <AddUpdateModal
        open={addVisible}
        setOpen={setAddVisible}
        Component={(props) => (
          <AddPromoCode
            {...props}
            onRequestClose={setAddVisible.bind(this, false)}
          />
        )}
        title={"Add Promo Code"}
      />
    </div>
  );
}
