import Swal from "sweetalert2";

export default class SweetAlert {
  static alert(
    message,
    icon = "success",
    title = "Success",
    showCancelButton = false
  ) {
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
      confirmButtonText: "Ok",
      confirmButtonColor: "red",
      showCancelButton: showCancelButton, // Show the close/cancel button
      cancelButtonText: "Close", // Text for the cancel button
      cancelButtonColor: "#d33", // Optional: color for the cancel button
      allowOutsideClick: true, // Allows closing the modal by clicking outside
      allowEscapeKey: true, // Allows closing the modal by pressing Escape
      customClass: {
        popup: "z-[99999]", // Apply Tailwind z-index class
      },
    });
  }

  static confirmation(
    message,
    title = "Are you sure?",
    showCancelButton = true,
    confirmButtonColor = "#3085d6",
    cancelButtonColor = "#d33",
    confirmButtonText = "Yes",
    cancelButtonText = "No",
    icon = "warning"
  ) {
    return new Promise((res) => {
      Swal.fire({
        title: title,
        text: message,
        icon: icon,
        confirmButtonText,
        cancelButtonText,
        showCancelButton,
        confirmButtonColor,
        cancelButtonColor,
      }).then((result) => {
        res(result.isConfirmed);
        /* Read more about isConfirmed, isDenied below */
        //   if (result.isConfirmed) {
        //     Swal.fire("Saved!", "", "success");
        //   } else if (result.isDenied) {
        //     Swal.fire("Changes are not saved", "", "info");
        //   }
      });
    });
  }
}
