import React from "react";
import { Textarea } from "../ui/textarea";
import ErrorText from "./error-text";
import { Label } from "../ui/label";

export default function FormikTextArea({
  formikProps,
  onChange = () => {},
  name,
  placeholder,
  style,
  className, // other Props
  type,
  size,
  color,
  radius,
  variant,
  shadow,
  removeWrapper = false,
  label,
  maxLength,
}) {
  return (
    <div>
      {label && (
        <Label className="mb-3" htmlFor="inputId">
          {label}
        </Label>
      )}
      <Textarea
        value={formikProps.values[name]}
        onChange={(e) => {
          formikProps.setFieldValue(name, e.target.value);
          onChange(e.target.value);
        }}
        placeholder={placeholder}
        style={style}
        className={className}
        type={type}
        size={size}
        color={color}
        radius={radius}
        variant={variant}
        shadow={shadow}
        removeWrapper={removeWrapper}
        maxLength={maxLength}
      />
      <ErrorText formikProps={formikProps} name={name} />
    </div>
  );
}
