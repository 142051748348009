import React, { useEffect, useRef } from "react";
import animationData from "../../assets/json/lotties/success.json";
import { Outlet } from "react-router-dom";

const PayementSuccessPage = () => {
  let animationRef = useRef(null);

  const getLottie = async () => {
    const lot = await import("lottie-web");

    lot.default.loadAnimation({
      loop: true,
      autoplay: true,
      animationData: animationData,
      container: animationRef.current,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    });
  };
  useEffect(() => {
    getLottie();
  }, []);

  return (
    <div className="min-h-screen overflow-y-auto flex justify-center items-center p-10">
      <div className="flex flex-col items-center">
        <div className="text-center">
          <div>
            <div ref={animationRef}></div>
            {/* <Lottie options={defaultOptions} height={250} width={250} /> */}
          </div>
          <div className="text-3xl md:text-4xl lg:text-5xl font-semibold text-success-900">
            Payment Successful
          </div>
          <div className="mt-3 text-default-600 text-sm md:text-base">
            We look forward to serving you again
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default PayementSuccessPage;
