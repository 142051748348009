import React, { useEffect, useRef } from "react";
// import animationData from "../../../assets/json/lotties/noData.json";
import animationData from "../assets/json/lotties/main-loader.json";

import { SiteLogo } from "../svg";
const LayoutLoader = () => {
  const animationRef = useRef();
  const getLottie = async () => {
    const lot = await import("lottie-web");

    lot.default.loadAnimation({
      loop: true,
      autoplay: true,
      animationData: animationData,
      container: animationRef.current,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    });
  };
  useEffect(() => {
    getLottie();
  }, []);
  return (
    <div className=" h-screen flex items-center justify-center flex-col space-y-2">
      {/* <SiteLogo className=" h-10 w-10 text-primary" />
      <span className=" inline-flex gap-1">
        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        Loading...
      </span> */}
      <div className="no-data-icon-styx" ref={animationRef}></div>
    </div>
  );
};

export default LayoutLoader;
