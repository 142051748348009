import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { CardContent } from "../../../components/ui/card";
import Card from "../../../components/ui/card-snippet";

const TypeFeaturesCard = ({
    featuresFormikProps,
    formikProps,
    title,
    data = [],
    onRemove = () => { },
    nameKey = "name",
    valueKey = "noofplayers",
}) => {
    const [features, setFeatures] = useState([]);

    useEffect(() => {
        setFeatures(data);
    }, [data]);


    const editFeature = (feature, index) => {
        console.log("featuresFormikProps:", featuresFormikProps);
        featuresFormikProps.setFieldValue("type", feature.type); // Assuming 'type' exists in feature
        featuresFormikProps.setFieldValue("name", feature[nameKey]); // Use nameKey to get the name
        featuresFormikProps.setFieldValue("noofplayers", feature[valueKey]); // Use valueKey to get the value
        featuresFormikProps.setFieldValue("isEdit", true);
        featuresFormikProps.setFieldValue("currentFeature", feature);
        featuresFormikProps.setFieldValue("index", index);
    };

    return formikProps && data.length > 0 ? (
        <div>
            <Card title={title} style={{ fontSize: "medium", fontWeight: 700 }}>
                <CardContent className="p-0">
                    <table className="table-auto w-full">
                        <tbody>
                            {features.map((entry, index) => (
                                <tr key={index}>
                                    <td style={{ fontSize: "16px" }}>
                                        {entry[nameKey]}
                                        {entry.noofplayers
                                            ? ` (${entry.noofplayers} players)`
                                            : entry[valueKey] ?? ""}
                                    </td>

                                    <td
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "4px",
                                        }}
                                    >
                                        <Icon
                                            icon="heroicons:x-circle"
                                            className="h-5 w-5"
                                            style={{ color: "red", cursor: "pointer" }}
                                            onClick={() => onRemove(entry)}
                                        />
                                        <Icon
                                            icon="heroicons:pencil"
                                            className="h-4 w-4"
                                            style={{ color: "#5f5fc5", cursor: "pointer" }}
                                            onClick={() => editFeature(entry, index)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </CardContent>
            </Card>
        </div>
    ) : null;
};

export default TypeFeaturesCard;
