import * as React from "react";
import { CardContent } from "../../../../components/ui/card";
import { Avatar, AvatarImage } from "../../../../components/ui/avatar";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import Common from "../../../../helper/common";
import {
  MatchResultTypeEnum,
  PaymentTypeEnum,
  TransactionLogStatusEnum,
} from "../../../../constants/appConstant";

const BooingFurthurDetails = ({ booking }) => {
  return (
    <div className="w-full flex flex-col h-full">
      <CardContent className="flex-1 p-0 w-full">
        <div className="overflow-x-auto mt-5">
          <p className="font-bold text-center text-xl">Players</p>

          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Side
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-left">
                  Player
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Is Creator
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Result
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Before Played Level
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  After Played Level
                </TableHead>
              </TableRow>
            </TableHeader>

            <TableBody className="[&_tr:last-child]:border-1">
              {booking?.players?.map((player) => (
                <TableRow key={player.id}>
                  <TableCell className="border-r text-center">
                    {player.side}-{player.position}
                  </TableCell>
                  <TableCell className="text-center border-default-200">
                    <div className="flex items-center gap-3 pr-4">
                      <div className="flex items-center gap-2">
                        <Avatar className=" rounded-[2px] h-8 w-8">
                          <AvatarImage
                            src={`${Common.AppConstant.imgBaseurl}${
                              player.playerImage ?? "noplayer-icon.jpg"
                            }`}
                          />
                          {/* <AvatarFallback>CD</AvatarFallback> */}
                        </Avatar>
                        <div className="text-sm  text-default-600 capitalize truncate max-w-[160px]">
                          {player.playerName}
                        </div>
                      </div>
                    </div>
                  </TableCell>

                  <TableCell className="border-default-200 text-center">
                    {player.iscreator ? "Yes" : "No"}
                  </TableCell>
                  <TableCell className="border-default-200  text-center">
                    {player.resultType
                      ? Common.Utility.getKeyByValue(
                          MatchResultTypeEnum,
                          player.resultType
                        )
                      : "-"}
                  </TableCell>
                  <TableCell className="border-default-200 text-center">
                    {player.beforePlayedLevel ?? "-"}
                  </TableCell>
                  <TableCell className="border-default-200 text-center">
                    {player.afterPlayedLevel ?? "-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <br />
        <div className="overflow-x-auto mt-5">
          <p className="font-bold text-center text-xl">Payment Details</p>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Player
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Payment Type
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Payment Status
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Amount
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Is By Club
                </TableHead>
              </TableRow>
            </TableHeader>

            <TableBody className="[&_tr:last-child]:border-1">
              {booking?.transactionLogs?.length > 0 ? (
                booking.transactionLogs.map((tlog, index) => (
                  <TableRow key={index}>
                    <TableCell className="border-r text-center border-default-200">
                      {tlog.playerName}
                    </TableCell>

                    <TableCell className="border-r border-default-200 text-center">
                      {Common.Utility.getKeyByValue(
                        PaymentTypeEnum,
                        tlog.paymentType
                      )}
                    </TableCell>

                    <TableCell className="border-r border-default-200 text-center">
                      {Common.Utility.getKeyByValue(
                        TransactionLogStatusEnum,
                        tlog.status
                      )}
                    </TableCell>

                    <TableCell className="border-r border-default-200 text-center">
                      {tlog.amount}
                    </TableCell>

                    <TableCell className="border-r border-default-200 text-center">
                      {tlog.isByClub ? "Yes" : "No"}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} className="text-center">
                    No payment received
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        <br />
        <div className="overflow-x-auto mt-5">
          <p className="font-bold text-center text-xl">Score Board</p>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Side
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Set 1
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Set 2
                </TableHead>
                <TableHead className="text-sm text-default-800 border-default-200 text-center">
                  Set 3
                </TableHead>
              </TableRow>
            </TableHeader>

            <TableBody className="[&_tr:last-child]:border-1">
              {booking?.groupedResults &&
              Object.keys(booking.groupedResults).length > 0 ? (
                Object.entries(booking.groupedResults).map(
                  ([side, results], index) => {
                    // Initialize sets
                    const sets = { set1: "-", set2: "-", set3: "-" };

                    // Map results to the correct set based on the round
                    results.forEach((result) => {
                      if (result.round === 1) sets.set1 = result.points;
                      if (result.round === 2) sets.set2 = result.points;
                      if (result.round === 3) sets.set3 = result.points;
                    });

                    return (
                      <TableRow key={index}>
                        <TableCell className="border-r text-center border-default-200">
                          {side}
                        </TableCell>
                        <TableCell className="border-r border-default-200 text-center">
                          {sets.set1}
                        </TableCell>
                        <TableCell className="border-r border-default-200 text-center">
                          {sets.set2}
                        </TableCell>
                        <TableCell className="border-r border-default-200 text-center">
                          {sets.set3}
                        </TableCell>
                      </TableRow>
                    );
                  }
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={4} className="text-center">
                    Result not uploaded
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </div>
  );
};

export default BooingFurthurDetails;
