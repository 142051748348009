import React from "react";
import { useThemeStore, useSidebar } from "../../../store";
import MobileFooter from "./mobile-footer";
import FooterLayout from "./footer-layout";
import { cn } from "../../../helper/utilityHelper";
import { useMediaQuery, useMounted } from "../../../hooks";

const Footer = ({ handleOpenSearch }) => {
  const { collapsed, sidebarType } = useSidebar();
  const { layout, footerType } = useThemeStore();
  const mounted = useMounted();
  const isMobile = useMediaQuery("(min-width: 768px)");

  if (!mounted) {
    return null;
  }
  if (!isMobile && sidebarType === "module") {
    return <MobileFooter handleOpenSearch={handleOpenSearch} />;
  }

  if (footerType === "hidden") {
    return null;
  }

  if (layout === "semibox") {
    return (
      <div className="xl:mx-20 mx-6">
        <FooterLayout
          className={cn(" rounded-md border", {
            "xl:ml-[72px]": collapsed,
            "xl:ml-[272px]": !collapsed,
            "sticky bottom-0": footerType === "sticky",
          })}
        >
          <FooterContent />
        </FooterLayout>
      </div>
    );
  }
  if (sidebarType !== "module" && layout !== "horizontal") {
    return (
      <FooterLayout
        className={cn("", {
          "xl:ml-[248px]": !collapsed,
          "xl:ml-[72px]": collapsed,
          "sticky bottom-0": footerType === "sticky",
        })}
      >
        <FooterContent />
      </FooterLayout>
    );
  }

  if (layout === "horizontal") {
    return (
      <FooterLayout
        className={cn("", {
          "sticky bottom-0": footerType === "sticky",
        })}
      >
        <FooterContent />
      </FooterLayout>
    );
  }

  return (
    <FooterLayout
      className={cn("", {
        "xl:ml-[300px]": !collapsed,
        "xl:ml-[72px]": collapsed,
        "sticky bottom-0": footerType === "sticky",
      })}
    >
      <FooterContent />
    </FooterLayout>
  );
};

export default Footer;

const FooterContent = () => {
  return (
    <div className="block md:flex md:justify-center text-muted-foreground">
      <p className="sm:mb-0 text-xs md:text-sm">
        Copyright © {new Date().getFullYear()}{" "}
      </p>
      {
        <p className="mb-0 text-xs md:text-sm">
          {" "}
          <a href="https://nascentinnovations.com/" target="_blank>">
            &nbsp;Nascent Innovations
          </a>
        </p>
      }
    </div>
  );
};
