import React from "react";
import LinkButton from "./link-or-button";
import MultiNestedMenus from "./multi-nested";
import { cn, isLocationMatch } from "../../../../helper/utilityHelper";
import {
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../../ui/collapsible";

const NestedMenus = ({
  nestedIndex,
  index,
  nestedMenus,
  locationName,
  toggleMulti,
  multiIndex,
}) => {
  return (
    <CollapsibleTrigger open={nestedIndex === index}>
      <CollapsibleContent className="CollapsibleContent">
        <ul className="sub-menu space-y-3 relative before:absolute before:left-4 before:top-0  before:h-[calc(100%-5px)]  before:w-[2px] before:bg-primary/10 dark:before:bg-primary/20 before:rounded">
          {nestedMenus?.map((item, j) => (
            <li
              className={cn(
                "block ml-[30px]  relative first:pt-4  before:absolute first:before:top-4 before: top-0 before:-left-[14px]  before:w-[2px]  before:h-0 before:transition-all before:duration-200 ",
                {
                  "before:bg-primary first:before:h-[calc(100%-16px)]  before:h-full":
                    isLocationMatch(item.href, locationName),
                  "last:pb-1": nestedIndex === index,
                }
              )}
              key={`sub_menu_${j}`}
            >
              <LinkButton
                toggleMulti={toggleMulti}
                item={item}
                index={j}
                multiIndex={multiIndex}
                locationName={locationName}
              >
                <div className={cn("pl-3  text-sm capitalize  font-normal ")}>
                  {item.title}
                </div>
              </LinkButton>
              <MultiNestedMenus
                menus={item?.child}
                multiIndex={multiIndex}
                index={j}
                locationName={locationName}
              />
            </li>
          ))}
        </ul>
      </CollapsibleContent>
    </CollapsibleTrigger>
  );
};

export default NestedMenus;
