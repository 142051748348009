import React from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";

export default function DialogModal({
  open,
  setOpen,
  title,
  Child,
  Footer = () => <div />,
}) {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-base font-medium ">{title}</DialogTitle>
        </DialogHeader>
        <Child />

        <DialogFooter className="mt-1 gap-2">
          <Footer />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
