import { Formik } from "formik";
import * as yup from "yup";
import { useRef, useState } from "react";
import { Button } from "../../../components/ui/button";
import {
  ErrorText,
  FormikInput,
  FormikSearchSelect,
  FormikTextArea,
} from "../../../components/formik-components";
import { Icon } from "@iconify/react";
import { Card, CardContent } from "../../../components/ui/card";
import Common from "../../../helper/common";

const formValidations = yup.object({
  sportId: yup.string().required("Select Sport"),
  question: yup.string().required("Question is required"),
  groupId: yup.string().required("Group Id is required"),
  options: yup.array().required().min(4, "Add at least 4 options"),
});

const optionFormValidations = yup.object({
  option: yup.string().required(),
  points: yup.string().required(),
});
const formInitialValue = {
  sportId: "",
  question: "",
  groupId: "",
  options: [],
};

const optionInitialValues = {
  option: "",
  points: "",
};

export default function AddQuestion({ onRequestClose }) {
  const [sports, setSports] = useState([{ id: 1, name: "Padel" }]);
  const [questions, setQuestions] = useState([
    {
      question: "Question 1",
      groupId: 1,
      options: [
        {
          option: "Option 1",
          points: 200,
        },
        {
          option: "Option 2",
          points: 200,
        },
      ],
    },
  ]);
  const formikRef = useRef();

  const onSubmitData = async (values, { setFieldValue }) => {
    if (
      questions.some(
        (item) => item.question.toLowerCase() === values.question.toLowerCase()
      )
    ) {
      Common.showToast("Can't add same questions");
    } else {
      setQuestions([...questions, values]);
      Object.keys(values).forEach((key) =>
        setFieldValue(key, formInitialValue[key])
      );
    }
  };

  const _onOptionSubmit = (values, { setFieldError, setFieldValue }) => {
    let formikProps = formikRef.current;
    let options = formikProps.values.options;
    console.log("options", options);
    if (
      options.some(
        (item) => item.option.toLowerCase() === values.option.toLowerCase()
      )
    ) {
      setFieldError("option", "Can not add same options");
    } else {
      formikProps.setFieldValue("options", [...options, values]);

      Object.keys(values).forEach((key) =>
        setFieldValue(key, optionInitialValues[key])
      );
    }
  };

  const Heading = ({ text }) => (
    <h6 class="text-lg font-bold dark:text-white">{text}</h6>
  );

  const EditAction = ({ onClick }) => (
    <Button
      size="icon"
      variant="soft"
      className="rounded-full"
      onClick={onClick}
    >
      <Icon icon="ic:outline-edit" className=" h-6 w-6 " />
    </Button>
  );

  const DeleteAction = ({ onClick }) => (
    <Button
      size="icon"
      variant="soft"
      color="destructive"
      className="rounded-full ml-2"
    >
      <Icon icon="ic:outline-delete" className=" h-6 w-6 " />
    </Button>
  );

  const Option = ({ item, showActions = true, onEdit, onDelete }) => (
    <div className="flex flex-row justify-between items-center">
      <div className="bg-primary-200 w-full flex flex-row justify-between rounded-md p-2 mr-3">
        <span className="text-primary">{item.option}</span>
        <span className="text-primary font-semibold">{item.points}</span>
      </div>
      {showActions && (
        <div className="flex flex-row">
          <EditAction onClick={onEdit} />
          <DeleteAction onClick={onDelete} />
        </div>
      )}
    </div>
  );

  const _renderQuestion = (item) => (
    <div className="border-b-2 pb-5 ">
      <div className="flex flex-row justify-between">
        <span className="text-primary font-bold text-wrap w-full">
          {item.question}
        </span>
        <div className="flex flex-row">
          <EditAction />
          <DeleteAction />
        </div>
      </div>
      <div className="pt-3">
        {item.options.map((item) => (
          <Option key={item.option} item={item} showActions={false} />
        ))}
      </div>
    </div>
  );

  return (
    <Card className="p-3">
      <CardContent>
        <Formik
          initialValues={formInitialValue}
          validationSchema={formValidations}
          innerRef={formikRef}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onSubmitData}
        >
          {(formikProps) => (
            <div className="md:grid md:grid-cols-3 mb-10 gap-2 space-y-3 md:space-y-0">
              <div className="col-span-1 border-r-2 pr-5">
                <div className="md:grid md:grid-cols-1  gap-2  space-y-3 md:space-y-0">
                  <div className="flex flex-col gap-3">
                    <FormikSearchSelect
                      formikProps={formikProps}
                      name={"sportId"}
                      data={sports}
                      placeholder={"Select Sport"}
                      label={"Select Sport"}
                      onChange={(val) => {}}
                    />
                  </div>

                  <div className="flex flex-col gap-3">
                    <FormikTextArea
                      formikProps={formikProps}
                      name={"question"}
                      placeholder={"Enter Question"}
                      label={"Question"}
                    />
                  </div>

                  <div className="flex flex-col gap-3 mb-3">
                    <FormikInput
                      formikProps={formikProps}
                      name={"groupId"}
                      placeholder={"Enter Group Id"}
                      label={"Group Id"}
                      type={"number"}
                    />
                  </div>

                  <Heading text={"Add Option"} />
                  <Formik
                    initialValues={{}}
                    validationSchema={optionFormValidations}
                    validateOnBlur={false}
                    validateOnChange={false}
                    onSubmit={_onOptionSubmit}
                  >
                    {(optionFormikProps) => (
                      <>
                        <div className="flex flex-col gap-3">
                          <FormikInput
                            formikProps={optionFormikProps}
                            name={"option"}
                            placeholder={"Enter Option"}
                            label={"Option"}
                          />
                        </div>

                        <div className="flex flex-col gap-3">
                          <FormikInput
                            formikProps={optionFormikProps}
                            name={"points"}
                            placeholder={"Enter Option Points (Max 1000)"}
                            label={"Option Points"}
                            type={"number"}
                          />
                        </div>

                        <div className="mt-3 flex justify-end">
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              optionFormikProps.handleSubmit();
                            }}
                            disabled={formikProps.isSubmitting}
                            type="button"
                            className="p-3 mt-3"
                          >
                            {"Add Option"}
                          </Button>
                        </div>
                      </>
                    )}
                  </Formik>

                  {formikProps.values.options.length > 0 && (
                    <Heading text={"Options"} />
                  )}
                  {formikProps.values.options.map((item) => (
                    <Option key={item.option} item={item} showActions={true} />
                  ))}

                  {formikProps.errors.options && (
                    <ErrorText formikProps={formikProps} name={"options"} />
                  )}

                  <div className="mt-5 w-full">
                    <Button
                      className="mt-5 w-full"
                      onClick={(e) => {
                        e.preventDefault();
                        formikProps.handleSubmit();
                      }}
                      disabled={formikProps.isSubmitting}
                      type="button"
                    >
                      {"Add Question"}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-span-2 pl-5">
                <div className="md:grid md:grid-cols-1 gap-2 space-y-3 md:space-y-0">
                  <Heading text={"Questions"} />

                  {questions.map(_renderQuestion)}
                </div>
              </div>
            </div>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
}
