import { useState, useEffect } from "react";
import { CardContent } from "../ui/card";
import Card from "../ui/card-snippet";
import { AppCheckBox, AppInput } from "../appComponents";
import Common from "../../helper/common";
import { IntMax, IntMin } from "../../constants/appConstant";

const SportFormat = ({ name, formikProps, data = [] }) => {
  const [formats, setFormats] = useState([]);

  useEffect(() => {
    setFormats(data);
    console.log("formats", data);
  }, [data]);

  useEffect(() => {
    formikProps.setFieldValue(
      name,
      formats.filter((item) => item.isSelected)
    );
  }, [formats]);

  return formikProps ? (
    <>
      <div className="flex flex-col gap-2">
        <Card title="Formats">
          <CardContent className="p-3">
            <div className=" md:grid md:grid-cols-1 gap-1">
              {formats.map((item,index) => (
                <div className="flex items-center ">
                  <div className="min-w-28">
                  <AppCheckBox
                    type="checkbox"
                    value={item.isSelected}
                    onChange={(val) =>
                      setFormats(
                        Common.Utility.getUpdatedArray(
                          formats,
                          item.id,
                          "isSelected",
                          val
                        )
                      )
                    }
                    name=""
                    formikProps={formikProps}
                    className="mb-1 items-center "
                    text={item.name}
                  />
                  </div>
                  <AppInput
                    formikProps={formikProps}
                    size="sm"
                    type={"number"}
                    value={item.charges}
                    min={IntMin}
                    max={IntMax}
                    onChange={(val) => {
                      debugger;
                      const numericVal = +val;
                      if (numericVal < IntMin) {
                        formikProps.setFieldValue("courtcharges", "");
                      } else if (numericVal > IntMax) {
                          Common.showToast(`Court charges cannot be greater than ${IntMax}`, "Error");
                        formikProps.setFieldValue("courtcharges", "");
                      } else {
                        formikProps.setFieldValue("courtcharges", numericVal);
                      }

                      setFormats(
                        Common.Utility.getUpdatedArray(
                          formats,
                          item.id,
                          "charges",
                          numericVal > IntMax
                            ? ""
                            : numericVal < IntMin
                            ? IntMin
                            : numericVal
                        )
                      );
                    }}
                    placeholder={"Charges"}
                    className="mb-1 ml-2"
                    disabled={!item.isSelected}
                  />
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  ) : null;
};

export default SportFormat;
