import React from "react";
import { Input } from "../ui/input";
import { Label } from "../ui/label";

export default function AppInput({
  value,
    onChange = () => { },
    onBlur = () => { },
  placeholder,
  style,
  className,
  type,
  size,
  color,
  radius,
  variant,
  shadow,
  removeWrapper = false,
  label,
  disabled,
}) {
  // Inline styles to remove the arrows for number input types
  const inputStyles =
    type === "number"
      ? {
          ...style,
          MozAppearance: "textfield", // Firefox
        }
      : style;

  return (
    <div className={className}>
      <div className="flex justify-between">
      <style>
        {`
          input[type="number"]::-webkit-outer-spin-button,
          input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        `}
      </style>
      {label && (
        <Label className="mb-2" htmlFor="inputId">
          {label}
        </Label>
      )}
      </div>
      <Input
        value={value || ""}
              onChange={(e) => onChange(e.target.value)}
              onBlur={(e) => onBlur(e.target.value)}
        placeholder={placeholder}
        style={style}
       // className={className}
        type={type}
        size={size}
        color={color}
        radius={radius}
        variant={variant}
        shadow={shadow}
        removeWrapper={removeWrapper}
        disabled={disabled}
      />
    
    </div>
  );
}
