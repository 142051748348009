import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../ui/sheet";
import { ScrollArea } from "../ui/scroll-area";

const AddUpdateModal = ({ title, Component, open, setOpen, size }) => {
  return (
    <Sheet open={open}>
      {/* <SheetTrigger asChild>{trigger}</SheetTrigger> */}

      <SheetContent
        className={`max-w-[${size}] p-0`}
        onClose={() => setOpen(false)}
      >
        <SheetHeader className="py-3 pl-4">
          <SheetTitle>{title}</SheetTitle>
        </SheetHeader>
        <hr />
        <div className="px-5 py-2 h-[calc(100vh)]">
          <ScrollArea className="h-full">
            {/* <div className=" text-center py-2">
              <h3 className="text-2xl font-bold text-default-700 ">
                Create a new account
              </h3>
              <p className="text-default-600  mt-1">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Omnis
                quod delectus amet.
              </p>
            </div> */}
            {/* form */}
            {Component && <Component />}
          </ScrollArea>
        </div>
        {/* <SheetFooter className="gap-3 pt-4 block">
          <div className="flex items-center gap-2.5 justify-center">
            <Button
              color="destructive"
              variant="outline"
              size="xs"
              onClick={() => setOpen(false)}
            >
              {" "}
              Cancel{" "}
            </Button>
            <Button size="xs">Submit</Button>
          </div>
        </SheetFooter> */}
      </SheetContent>
    </Sheet>
  );
};

export default AddUpdateModal;
