import React from "react";
import { MainSiteLogo } from "../../../../svg";
import { Label } from "../../../../components/ui/label";
import { Checkbox } from "../../../../components/ui/checkbox";
import * as yup from "yup";
import { loader } from "react-global-loader";
import { Formik } from "formik";
import { FormikInput } from "../../../../components/formik-components";
import { Button } from "../../../../components/ui/button";
import common from "../../../../helper/common";
import AppConstant, {
  SignUpTypeEnum,
  RoleEnum,
} from "../../../../constants/appConstant";
import { useAuth } from "../../../../contexts/authContext";
import { useMediaQuery } from "../../../../hooks";
import { Link, useNavigate } from "react-router-dom";

const schema = yup.object({
  email: yup.string().email().required("Required"),
  password: yup.string().min(3).required("Required"),
});

const LoginForm = () => {
  const [passwordType, setPasswordType] = React.useState("password");
  const isDesktop2xl = useMediaQuery("(max-width: 1530px)");

  const { setUser } = useAuth();
  const navigate = useNavigate();

  const togglePasswordType = () => {
    if (passwordType === "text") {
      setPasswordType("password");
    } else if (passwordType === "password") {
      setPasswordType("text");
    }
  };

  const isMobileBrowser = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|iphone|ipad|ipod|opera mini|iemobile|mobile/i.test(
      userAgent
    );
  };

  const onSubmit = async (data) => {
    const _data = {
      emailorphone: data.email,
      password: data.password,
      signupType: SignUpTypeEnum.EmailorPhone,
    };
    console.log("Calling...");
    let res = await common.ApiService.getInstance().request(
      "/ClubManagerSignIn",
      _data,
      "Post"
    );
    debugger;
    let user = res.data[0];
    common.setToken(user.token);
    common.setUser(user);
    global.user = user;
    setUser(user);

    let _route;
    if (global.user.roleId == RoleEnum.Super_Admin) {
      _route = "/app/sports";
    } else if (global.user.roleId == RoleEnum.Club_Manager) {
      _route = "/app/dashboard";
    }

    setTimeout(() => {
      // loader.show();
      navigate(_route, { replace: true });
      if (isMobileBrowser()) {
        window.location.reload();
      }
    }, 100);
  };

  return (
    <div className="w-full py-10">
      <MainSiteLogo className="text-primary h-28" />

      {/* <div className="2xl:mt-8 mt-6 2xl:text-3xl text-2xl font-bold text-default-900">
        PlayPro
      </div> */}
      <div className="2xl:text-lg text-base text-default-600 2xl:mt-2 leading-6">
        Where Professionals Connect
      </div>
      <Formik
        onSubmit={onSubmit}
        validationSchema={schema}
        initialValues={{
          email: "",
          password: "",
        }}
      >
        {(formikProps) => (
          <div className="mt-5 2xl:mt-7">
            <FormikInput
              placeholder={"Email"}
              name={"email"}
              label={"Email"}
              formikProps={formikProps}
            />
            <div className="mt-5 2xl:mt-3">
              <FormikInput
                type={"password"}
                placeholder={"Password"}
                name={"password"}
                label={"Password"}
                formikProps={formikProps}
              />
            </div>
            {/* <div>
            <Label
              htmlFor="email"
              className="mb-2 font-medium text-default-600"
            >
              Email
            </Label>
            <Input
              // disabled={isPending}
              {...register("email")}
              type="email"
              id="email"
              className={cn("", {
                "border-destructive": errors.email,
              })}
              size={!isDesktop2xl ? "xl" : "lg"}
            />
          </div>
          {errors.email && (
            <div className=" text-destructive mt-2">{errors.email.message}</div>
          )} */}

            {/* <div className="mt-3.5">
            <Label
              htmlFor="password"
              className="mb-2 font-medium text-default-600"
            >
              Password
            </Label>
            <div className="relative">
              <Input
                //   disabled={isPending}
                {...register("password")}
                type={passwordType}
                id="password"
                className="peer"
                size={!isDesktop2xl ? "xl" : "lg"}
                placeholder=" "
              />

              <div
                className="absolute top-1/2 -translate-y-1/2 right-4 cursor-pointer"
                onClick={togglePasswordType}
              >
                {passwordType === "password" ? (
                  <Icon
                    icon="heroicons:eye"
                    className="w-5 h-5 text-default-400"
                  />
                ) : (
                  <Icon
                    icon="heroicons:eye-slash"
                    className="w-5 h-5 text-default-400"
                  />
                )}
              </div>
            </div>
          </div> */}
            {/* {errors.password && (
            <div className=" text-destructive mt-2">
              {errors.password.message}
            </div>
          )} */}

            <div className="mt-5 mb-8 flex flex-wrap gap-2">
              <div className="flex-1 flex items-center gap-1.5">
                <Checkbox
                  size="sm"
                  className="border-default-300 mt-[1px]"
                  id="isRemebered"
                />
                <Label
                  htmlFor="isRemebered"
                  className="text-sm text-default-600 cursor-pointer whitespace-nowrap"
                >
                  Remember me
                </Label>
              </div>
              {/* <Link
                href="/auth/forgot"
                className="flex-none text-sm text-primary"
              >
                Forget Password?
              </Link> */}
            </div>
            <Button
              className="w-full"
              type="button"
              onClick={formikProps.handleSubmit}
              //   disabled={isPending}
              size={!isDesktop2xl ? "lg" : "md"}
            >
              {/* {isPending &&} */}
              {/* <Loader2 className="mr-2 h-4 w-4 animate-spin" /> */}
              {/* {isPending ? "Loading..." :  */}
              {"Sign In"}
            </Button>
          </div>
        )}
      </Formik>
      {/* <div className="mt-5 2xl:mt-8 text-center text-base text-default-600">
        Don't have an account?{" "}
        <Link href="/auth/register" className="text-primary">
          Sign Up
        </Link>
      </div> */}
    </div>
  );
};

export default LoginForm;
