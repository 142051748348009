import React, { useRef, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import Common from "../../helper/common";
import {
  FormikFileUploaderMultiple,
  FormikInput,
  FormikSearchSelect,
  FormikTextArea,
} from "../../components/formik-components";
import { Button } from "../../components/ui/button";
import { Card, CardContent } from "../../components/ui/card";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import { Icon } from "@iconify/react";
import TimingsCard from "../../components/cards/timingsCard";
import { DayOfWeekEnum } from "../../constants/appConstant";
import { Checkbox } from "../../components/ui/checkbox";
import { Label } from "../../components/ui/label";
import { AppCheckBox } from "../../components/appComponents";

const formValidations = yup.object({
  courtId: yup.object().required("Court is Requied"),
});
const formInitialValues = {
  countryId: 1,
  cityId: 1,
  stateId: 1,
  facilities: [
    { facility: "WiFi", isSelected: true },
    { facility: "Special Access", isSelected: true },
    { facility: "Lockers", isSelected: false },
    { facility: "Online Streaming", isSelected: true },
  ],
};

export default function ClubSettings() {
  const formikRef = useRef();

  const [timings, setTimings] = useState(
    Common.Utility.enumToArray(DayOfWeekEnum).map((day) => ({
      ...day,
      isOn: false,
      dayOfweek: day.id,
      openingTime: "",
      closingTime: "",
    }))
  );

  const _onSubmit = (values, action) => {
    console.log(values);
  };

  const Heading = ({ text }) => (
    <h4 class="text-2xl font-bold dark:text-white mb-5">{text}</h4>
  );

  const _renderBasic = () => (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidations}
      innerRef={formikRef}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={_onSubmit}
    >
      {(formikProps) => (
        <Card>
          <CardContent className="p-3">
            <div className="md:grid md:grid-cols-7 mb-10 gap-2 space-y-3 md:space-y-0">
              <div className="col-span-3">
                <Heading text={"Contact info"} />
                <div className="md:grid md:grid-cols-1  gap-2  space-y-3 md:space-y-0">
                  <div className="flex flex-col gap-2">
                    <FormikInput
                      formikProps={formikProps}
                      name={"name"}
                      placeholder={"Enter Club Name"}
                      label={"Club Name"}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <FormikInput
                      formikProps={formikProps}
                      name={"name"}
                      placeholder={""}
                      label={"Website or social network"}
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <FormikInput
                      formikProps={formikProps}
                      name={"name"}
                      placeholder={""}
                      label={"Club Phone number"}
                    />
                  </div>

                  {/* <div className="md:grid">
                <div className="md:grid md:grid-cols-2  gap-6 space-y-3 md:space-y-0">
                  <div className="flex flex-col gap-3">
                    <FormikInput
                      formikProps={formikProps}
                      name={"startDateTime"}
                      placeholder={"Start Time"}
                      label={"Start Time"}
                      type={"time"}
                    />
                  </div>

                  <div className="flex flex-col gap-3">
                    <FormikInput
                      formikProps={formikProps}
                      name={"endDateTime"}
                      placeholder={"End Time"}
                      label={"End Time"}
                      type={"time"}
                    />
                  </div>
                </div>
              </div> */}
                </div>
              </div>
              <div className="col-span-1 h-full flex justify-center">
                <span className="border-r-2" />
              </div>

              <div className="col-span-3">
                <Heading text={"About"} />
                <div className="md:grid md:grid-cols-1  gap-2  space-y-3 md:space-y-0">
                  <div className="flex flex-col gap-2">
                    <FormikTextArea
                      formikProps={formikProps}
                      name={"name"}
                      placeholder={"Enter description"}
                      label={"Description"}
                      max={2000}
                      type={"textarea"}
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-7">
                <div
                  style={{
                    height: 1,
                    backgroundColor: "#D3D3D3",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                />
              </div>

              {/* Row 2 Starts Here */}
              <div className="col-span-3">
                <Heading text={"Location"} />
                <div className="md:grid md:grid-cols-1  gap-2  space-y-3 md:space-y-0">
                  <div className="flex flex-col gap-2">
                    <FormikSearchSelect
                      formikProps={formikProps}
                      name={"countryId"}
                      data={[{ name: "Pakistan", id: 1 }]}
                      placeholder={"Select Country"}
                      label={"Country"}
                      disabled
                    />
                  </div>

                  <div className="md:grid">
                    <div className="md:grid md:grid-cols-2  gap-6 space-y-3 md:space-y-0">
                      <div className="flex flex-col gap-3">
                        <FormikSearchSelect
                          formikProps={formikProps}
                          name={"stateId"}
                          data={[{ name: "Sindh", id: 1 }]}
                          placeholder={"Select State"}
                          label={"State"}
                        />
                      </div>

                      <div className="flex flex-col gap-3">
                        <FormikSearchSelect
                          formikProps={formikProps}
                          name={"cityId"}
                          data={[{ name: "Karachi", id: 1 }]}
                          placeholder={"Select City"}
                          label={"City"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2">
                    <FormikInput
                      formikProps={formikProps}
                      name={"name"}
                      placeholder={""}
                      label={"Postal Code"}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-1 h-full flex justify-center">
                <span className="border-r-2" />
              </div>

              <div className="col-span-3">
                <Heading text={"Images"} />
                {/* <Card title="Upload Multiple Files" className="p-5"> */}
                {/* <p className="text-sm text-default-400 dark:text-default-600 p-5 mb-4 max-w-2xl">
                    Upload multiple files and images here. They will display
                    individually, and you can remove or delete them one by one
                    or all at once.
                  </p> */}
                <FormikFileUploaderMultiple
                  title="Drop Images here or click to upload"
                  description="PNG, JPG, JPEG files only and maximum size of 3 MB each"
                />
                {/* </Card> */}
              </div>

              <div className="col-span-7">
                <div
                  style={{
                    height: 1,
                    backgroundColor: "#D3D3D3",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                />
              </div>

              <div className="col-span-3">
                <Heading text={"Facilities"} />
                <div className="md:grid md:grid-cols-1  gap-2  space-y-3 md:space-y-0">
                  {formikProps.values.facilities.map((item) => (
                    <div
                      className="flex-1 flex items-center gap-1.5"
                      key={item.facility}
                    >
                      <AppCheckBox
                        size="sm"
                        className="border-default-300 mt-[1px]"
                        id="isRemebered"
                        checked={item.isSelected}
                        onChange={() => {
                          formikProps.setFieldValue(
                            "facilities",
                            formikProps.values.facilities.map((facility) =>
                              item == facility
                                ? {
                                    ...facility,
                                    isSelected: !facility.isSelected,
                                  }
                                : facility
                            )
                          );
                        }}
                      />
                      <Label
                        htmlFor="isRemebered"
                        className="text-sm text-default-600 cursor-pointer whitespace-nowrap"
                      >
                        {item.facility}
                      </Label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-5">
                <Button
                  className="mt-5"
                  onClick={(e) => {
                    e.preventDefault();
                    formikProps.handleSubmit();
                  }}
                  disabled={formikProps.isSubmitting}
                  type="button"
                >
                  {"  Save Changes  "}
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </Formik>
  );

  const _renderTimings = () => (
    <Formik initialValues={{}}>
      {(formikProps) => (
        <TimingsCard
          formikProps={formikProps}
          name={"timings"}
          data={timings}
        />
      )}
    </Formik>
  );

  const _renderBooking = () => (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidations}
      innerRef={formikRef}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={_onSubmit}
    >
      {(formikProps) => (
        <Card>
          <CardContent className="p-3">
            <div className="md:grid md:grid-cols-7 mb-10 gap-2 space-y-3 md:space-y-0">
              <div className="col-span-3">
                <Heading text={"Booking"} />
                <div className="md:grid md:grid-cols-1  gap-2  space-y-3 md:space-y-0">
                  <div className="flex flex-col gap-2">
                    <FormikInput
                      formikProps={formikProps}
                      name={"name"}
                      placeholder={"Cancellation policy (hours)"}
                      label={"Cancellation policy (hours)"}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <FormikInput
                      formikProps={formikProps}
                      name={"name"}
                      placeholder={""}
                      label={"Maximum bookings/day"}
                    />
                  </div>
                  <div className="mt-5">
                    <Button
                      className="mt-5"
                      onClick={(e) => {
                        e.preventDefault();
                        formikProps.handleSubmit();
                      }}
                      disabled={formikProps.isSubmitting}
                      type="button"
                    >
                      {"  Save Changes  "}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </Formik>
  );

  return (
    <div className="w-full">
      <Tabs defaultValue="basic" className="w-full inline-block">
        <TabsList className=" border bg-background  ">
          <TabsTrigger
            className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
            value="basic"
          >
            <Icon icon="lucide:settings-2" className="h-5 w-5  mr-2 " />
            Basic
          </TabsTrigger>
          <TabsTrigger
            value="timings"
            className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
          >
            <Icon icon="lucide:calendar-clock" className="h-5 w-5  mr-2 " />
            Timings
          </TabsTrigger>
          <TabsTrigger
            value="booking"
            className="data-[state=active]:bg-primary data-[state=active]:text-primary-foreground"
          >
            {/* <Icon icon="lucide:layout-list" className="h-5 w-5  mr-2 " /> */}
            <Icon icon="lucide:notebook-pen" className="h-5 w-5  mr-2 " />
            Booking
          </TabsTrigger>
        </TabsList>

        <TabsContent value="basic">{_renderBasic()}</TabsContent>
        <TabsContent value="timings">{_renderTimings()}</TabsContent>
        <TabsContent value="booking">{_renderBooking()}</TabsContent>
      </Tabs>
    </div>
  );
}
