import { Formik } from "formik";
import * as yup from "yup";
import toast from "react-hot-toast";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "../../../components/ui/button";
import {
  FormikInput,
  FormikSelect,
  FormikSearchSelect,
  FormikDate,
  FormikSearchSectionSelect,
} from "../../../components/formik-components";
import Common from "../../../helper/common";

import {
  IntCourtPriceMin,
  IntMax,
  IntMin,
  PaymentStatusEnum,
  PaymentTypeEnum,
  ResultStatus,
  ToCSharpFormat,
  DateminFormat,
  respEnum,
  SlotStatusEnum,
} from "../../../constants/appConstant";

import { useNavigate } from "react-router-dom";
import AddCustomer from "../../clubCustomers/components/addCustomer";
import animationData from "../../../assets/json/lotties/noData.json";
import common from "../../../helper/common";

const formValidations = yup.object({
  courtId: yup.object().required("Court is Requied"),
  playerId: yup.number().required("Customer is Required"),
  noofplayers: yup.number().required("Select # no of players"),
  courtcharges: yup
    .number()
    .required("Enter Court Price")
    .min(0, "Court price can't be negative")
    .max(IntMax),
  bookingDate: yup
    .date()
    .required("Booking Date is Requied")
      .min(new Date(2024, 10, 5), "Date should be greater than previous date "),
    //.min(Common.Utility.today, "Date should be greater than previous date "),
  paymentStatus: yup.date().required("Payment Status is Requied"),
  startDateTime: yup
    .string()
    .required("Start Time is required")
    .test(
      "is-valid-time",
      "Start Time format should be in 00, 30 or 23:59 format",
      (value) => Common.Utility.CheckTime(value)
    )
    .matches(
      /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/,
      "Invalid time format (HH:mm)"
    ),

  endDateTime: yup
    .string()
    .required("End time is required")
    .test(
      "is-valid-time",
      "End Time format should be in 00, 30 23:59 format",
      (value) => Common.Utility.CheckTime(value)
    )
    .matches(
      /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/,
      "Invalid time format (HH:mm)"
    ),
});
const formInitialValue = {
  bookingDate: Common.moment().format(DateminFormat),
  courtId: undefined,
  noofplayers: 4,
};

export default function AddBooking({ onClose }) {
  const [customerList, setCustomerList] = useState([]);
  const [courtList, setCourtList] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [bookedSlots, setBookedSlots] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const animationRef = useRef();
  const lottieInstance = useRef(null);

  const getLottie = async () => {
    const lot = await import("lottie-web");

    // Destroy the existing animation if there is one
    if (lottieInstance.current) {
      lottieInstance.current.destroy();
    }

    // Create a new animation instance and store it in the ref
    lottieInstance.current = lot.default.loadAnimation({
      loop: true,
      autoplay: true,
      animationData: animationData,
      container: animationRef.current,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    });
  };

  const formikRef = useRef();
  useEffect(() => {
    getLottie();

    return () => {
      if (lottieInstance.current) {
        lottieInstance.current.destroy();
      }
    };
  }, [bookedSlots]);

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = async () => {
    getCustomers();
    // await Common.delay();
    getCourts();
  };

  const getCustomers = async () => {
    return new Promise(async (res) => {
      const response = await Common.ApiService.getInstance().request(
        "ClubPlayersddl",
        {},
        "Get",
        false
      );
      setCustomerList(response?.data ?? []);
      setTimeout(res, 1000);
    });
  };

  const getBookedSlots = async (courtId, date, duration = 60) => {
    if (!courtId || !date) return;

    let url = `CheckCourtAvailability?courtId=${courtId}&playDate=${date}&duration=${duration}`;

    debugger;

    const response = await Common.ApiService.getInstance().request(url);
    setBookedSlots(response?.data ?? []);
  };

  // const getBookedSlots = async (courtId, date) => {
  //     debugger;
  //     if (!courtId || !date) return;

  //     const response = await Common.ApiService.getInstance().request(
  //         `GetCourtBookedSlots?courtId=${courtId}&date=${date}`,
  //         {},
  //         "POST"
  //     );
  //     setBookedSlots(response?.data ?? []);
  // };

  // const getCourts = async () => {
  //     const response = await Common.ApiService.getInstance().request(
  //         "GetSportWiseCourtddl",{},"Get",false
  //     );
  //     setCourtList(response?.data ?? []);
  // };

  const getCourts = async () => {
    const response = await Common.ApiService.getInstance().request(
      "GetSportWiseCourtddl"
    );

    if (response?.status == respEnum.Success) {
      setCourtList(response?.data ?? []);
    }
  };

  const onSubmitData = async (d, action) => {
    let data = {
      ...d,
      courtId: d.courtId.value,
      playerId: +d.playerId,
      noofplayers: +d.noofplayers,
      paymentType: +d.paymentType,
      paymentStatus: +d.paymentStatus,
      courtcharges: +d.courtcharges,
      total: +d.courtcharges,
      startDateTime: Common.moment(
        `${d.bookingDate} ${d.startDateTime}`
      ).format(ToCSharpFormat),
      endDateTime: Common.moment(`${d.bookingDate} ${d.endDateTime}`).format(
        ToCSharpFormat
      ),
    };

    if (data.startDateTime >= data.endDateTime) {
      toast.error("End time must be greater");
      return;
    }

    //if (data.startDateTime < Common.Utility.todayDatetime /*|| data.endDateTime < Common.Utility.todayDatetime*/) {
    //  toast.error("Start Time and End time should be greater than previous");
    //  return;
      //}

      const tenDaysAgo = new Date();
      tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);

      if (data.startDateTime < tenDaysAgo || data.endDateTime < tenDaysAgo) {
          toast.error("Start Time and End Time should not be earlier than 10 days ago.");
          return;
      }


    if (data.paymentStatus == PaymentStatusEnum.Paid && !data.paymentType) {
      toast.error("Please Provide the Payment Type");
      return;
    }

    if (data.paymentType == PaymentTypeEnum.Paid) {
      data.total = +d.courtcharges;
      data.receivedAmount = +data.total;
      data.remainingAmount = data.total - data.receivedAmount;
    } else if (!data.paymentStatus) {
      data.paymentType = null;
    }

    console.log("onSubmitData", data);

    let response = await Common.ApiService.getInstance().request(
      "AddClubBooking",
      data,
      "POST"
    );
    action.setSubmitting(false);
    if (response.status == ResultStatus.Success) {
      Common.showToast("Booking Created successfully!", "Success", "success");
      onClose(true);
    }
  };

  const _onCustomerAdded = useCallback(async (id) => {
    formikRef.current.setFieldValue("playerId", id);
    await getCustomers();
  }, []);

  const _addCustomer = useCallback(() => (
    <AddCustomer
      id={undefined}
      onClose={_onCustomerAdded}
      open={open}
      setOpen={setOpen}
      bookingModal={true}
    />
  ));

  const handleCourtChange = (selectedCourt) => {
    debugger;

    let durations =
      selectedCourt?.formats?.map((item) => ({
        id: item.value,
        name: item.label,
      })) ?? [];
    setDurationList(durations);

    getBookedSlots(
      selectedCourt.value,
      formikRef.current.values.bookingDate,
      formikRef.current.values.duration
    );
  };

  const handleSlotClick = (slot) => {
    debugger;
    if (slot.status == SlotStatusEnum.Booked) {
      navigate(`/app/bookingDetails?id=${slot.bookingId}`);
    } else if (slot.status == SlotStatusEnum.Available) {
      const formattedStartTime = Common.Utility.formatTimeToHHMM(slot.start);
      const formattedEndTime = Common.Utility.formatTimeToHHMM(slot.end);

      formikRef.current.setFieldValue("startDateTime", formattedStartTime);
      formikRef.current.setFieldValue("endDateTime", formattedEndTime);
    }
  };

  return (
    <Formik
      initialValues={formInitialValue}
      validationSchema={formValidations}
      innerRef={formikRef}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmitData}
    >
      {(formikProps) => (
        <div className="md:grid md:grid-cols-3 mb-10 gap-2 space-y-3 md:space-y-0">
          <div className="col-span-1">
            <div className="md:grid md:grid-cols-1  gap-2  space-y-3 md:space-y-0">
              <div className="flex flex-col gap-2">
                <FormikSearchSelect
                  formikProps={formikProps}
                  name={"playerId"}
                  data={customerList}
                  placeholder={"Select Customer"}
                  label={"Select Customer"}
                  labelButton={_addCustomer}
                  onChange={(val) => {
                    getBookedSlots(
                      formikProps.values.courtId?.value,
                      formikProps.values.bookingDate
                    );
                  }}
                />
              </div>

              <div className="md:grid">
                <div className="md:grid md:grid-cols-2  gap-6  space-y-3 md:space-y-0">
                  <div className="flex flex-col gap-3">
                    <FormikSearchSectionSelect
                      formikProps={formikProps}
                      name={"courtId"}
                      label={"Court"}
                      data={courtList}
                      onChange={(val) => handleCourtChange(val)}
                    />
                  </div>
                  <div className="flex flex-col gap-3">
                    <FormikSelect
                      formikProps={formikProps}
                      name={"duration"}
                      label={"Duration"}
                      data={durationList}
                      onChange={(val) => {
                        getBookedSlots(
                          formikProps.values.courtId?.value,
                          formikProps.values.bookingDate,
                          val
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              <FormikDate
                formikProps={formikProps}
                name={"bookingDate"}
                type={"date"}
                label={"Booking Date"}

                onChange={(val) => {
                  //Common.Utility.checkPreviousDate(val, "Booking Date");
                  getBookedSlots(
                    formikProps.values.courtId?.value,
                    val,
                    formikProps.values.duration
                  );
                }}
              />

              <div className="md:grid">
                <div className="md:grid md:grid-cols-2  gap-6 space-y-3 md:space-y-0">
                  <div className="flex flex-col gap-3">
                    <FormikInput
                      formikProps={formikProps}
                      name={"startDateTime"}
                      placeholder={"Start Time"}
                      label={"Start Time"}
                      type={"time"}
                    />
                  </div>

                  <div className="flex flex-col gap-3">
                    <FormikInput
                      formikProps={formikProps}
                      name={"endDateTime"}
                      placeholder={"End Time"}
                      label={"End Time"}
                      type={"time"}
                    />
                  </div>
                </div>
              </div>

              <div className="md:grid">
                <div className="md:grid md:grid-cols-2  gap-6 space-y-3 md:space-y-0">
                  <div className="flex flex-col gap-3">
                    <FormikSelect
                      formikProps={formikProps}
                      name={"noofplayers"}
                      placeholder={"No of Players"}
                      label={"No of Player"}
                      data={[
                        { id: 2, name: 2 },
                        { id: 4, name: 4 },
                      ]}
                    />
                  </div>
                  <div className="flex flex-col gap-3">
                    <FormikInput
                      formikProps={formikProps}
                      name={"courtcharges"}
                      placeholder={"Court Charges"}
                      label={"Court Charges"}
                      type={"number"}
                      max={IntMax}
                      min={IntCourtPriceMin}
                      onChange={(value) => {
                        if (value < IntMin && value != "") {
                          formikProps.setFieldValue("courtcharges", IntMin);
                        } else if (value > IntMax) {
                          toast.error(
                            `Court charges cannot be greater than ${IntMax}`
                          );
                          formikProps.setFieldValue("courtcharges", "");
                        } else {
                          formikProps.setFieldValue("courtcharges", value);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="md:grid">
                <div className="md:grid md:grid-cols-2  gap-6 space-y-3 md:space-y-0">
                  <div className="flex flex-col gap-3">
                    <FormikSelect
                      formikProps={formikProps}
                      name={"paymentStatus"}
                      placeholder={"Payment Status"}
                      label={"Payment Status"}
                      // data={Common.Utility.enumToArray(PaymentStatusEnum)}
                      data={[
                        { id: PaymentStatusEnum.Paid, name: "Paid" },
                        { id: PaymentStatusEnum.Un_Paid, name: "Un Paid" },
                      ]}
                      onChange={(val) => {
                        formikProps.setFieldValue("paymentStatus", val);

                        if (val === PaymentStatusEnum.Paid) {
                          formikProps.setFieldValue(
                            "paymentType",
                            formikProps.values.paymentType
                          );
                        } else {
                          formikProps.setFieldValue("paymentType", null);
                        }
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-3">
                    <FormikSelect
                      formikProps={formikProps}
                      name={"paymentType"}
                      placeholder={"Payment Type"}
                      label={"Payment Type"}
                      data={Common.Utility.enumToArray(PaymentTypeEnum)}
                      disabled={
                        formikProps.values.paymentStatus !==
                        PaymentStatusEnum.Paid
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <Button
                  className="mt-5"
                  onClick={(e) => {
                    e.preventDefault();
                    formikProps.handleSubmit();
                  }}
                  disabled={formikProps.isSubmitting}
                  type="button"
                >
                  {" "}
                  {formikProps.isSubmitting ? "Saving..." : "Add Booking"}{" "}
                </Button>
              </div>
            </div>
          </div>

          {/* No Slots Data */}
          {bookedSlots.length <= 0 && (
            <div className="col-span-2">
              <div className="px-0 md:px-3  py-6">
                <div className="grid grid-cols-1 gap-2 flex justify-center items-center">
                  <div className="no-data-icon-sty" ref={animationRef}></div>
                  <h5 className="text-md text-bold text-center mb-4">
                    Please select a court for available time slots.
                  </h5>
                </div>

                {/* <div className="grid  grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-2">
                                    {bookedSlots.map((bs) => (
                                        
                                        <Button 
                                            color="destructive"
                                            variant="soft"
                                            className="rounded text-xs"
                                            onClick={() => {
                                                navigate(`/app/bookingDetails?id=${bs.bookingId}`);
                                            }}
                                        >
                                            {bs.slot}
                                        </Button>

                                    ))}
                                    {Array.from({ length: 10 }, (_, index) => (

                                        <Button key={index} color="destructive" variant="soft" disabled className="rounded  font-bold text-xs">12:44 pm - 06:50 pm</Button>
                                    ))}
                                    {Array.from({ length: 37 }, (_, index) => (

                                        <Button key={index} color="success" variant="soft" className="rounded font-medium text-xs">12:44 pm - 06:50 pm</Button>
                                    ))}
                                </div> */}
              </div>
            </div>
          )}
          {/* No Slots Data End */}

          {bookedSlots.length > 0 && (
            <div className="col-span-2">
              <div className="px-3 py-6">
                <div className="grid  grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-2">
                  {bookedSlots.map((bs, index) => (
                    <Button
                      key={index}
                      color={
                        bs.status == SlotStatusEnum.Available
                          ? "success"
                          : "destructive"
                      }
                      variant="soft"
                      className="rounded text-xs"
                      // onClick={() => {
                      //     navigate(`/app/bookingDetails?id=${bs.bookingId}`);
                      // }}
                      onClick={() => handleSlotClick(bs)}
                    >
                      {Common.Utility.formatTimeToAMPM(bs.start)} -{" "}
                      {Common.Utility.formatTimeToAMPM(bs.end)}
                    </Button>
                  ))}
                  {/* {Array.from({ length: 10 }, (_, index) => (

                                        <Button key={index} color="destructive" variant="soft" disabled className="rounded  font-bold text-xs">12:44 pm - 06:50 pm</Button>
                                    ))}
                                    {Array.from({ length: 37 }, (_, index) => (

                                        <Button key={index} color="success" variant="soft" className="rounded font-medium text-xs">12:44 pm - 06:50 pm</Button>
                                    ))} */}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Formik>
  );
}
