import moment from "moment";
import {
  setUser,
  getUser,
  clearUser,
  setToken,
  getToken,
  clearToken,
} from "./sessionHelper";
import Utility from "./utilityHelper";
import SweetAlert from "./sweetAlert";
import AppConstant from "../constants/appConstant";
import ApiService from "../services/HttpServiceManager";
import { ImageConstant } from "../constants/imageConstant";
import { toast } from "../components/ui/use-toast";

const delay = (milliseconds = 500) =>
  new Promise((res) => setTimeout(res, milliseconds));

const showToast = (message, title = "Error", type = "destructive") =>
  toast({
    title: title,
    description: <>{message}</>,
    color:type,
  });

export default {
  AppConstant,
  moment,
  ApiService,
  setUser,
  getUser,
  clearUser,
  setToken,
  getToken,
  clearToken,
  Utility,
  delay,
  SweetAlert,
  showToast,
  ImageConstant,
};
