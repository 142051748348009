import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import moment from "moment";
import {
  TournamentVenueTypeEnum,
  ViewDateFormat,
} from "../../constants/appConstant";
import AdvanceTable from "../../components/advanceTable";
import Common from "../../helper/common";
import { Button } from "../../components/ui/button";
import { useNavigate } from "react-router-dom";

const Tournaments = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    getData(1);
  }, []);

  const getData = async (
    pageNumber,
    searchTerm = "",
    recordsToDisplay = null
  ) => {
    const response = await Common.ApiService.getInstance().request(
      "GetTournaments",
      {
        pageNumber: pageNumber,
        q: searchTerm,
        sportId: null,
      },
      "Post"
    );
    if (response?.data) {
      let obj = {
        ...response.data[0],
        data: response.data[0].data.map((item) => ({
            ...item,
            schedule: renderScheduleButton(item.isSchedule, item.id),
          joinStatus: `${item.teamsJoined}/${item.maxTeams} teams joined`,
          venueType: Common.Utility.getKeyByValue(
            TournamentVenueTypeEnum,
            item.venueType
          ),
          registrationDates: `${moment(item.registrationStartDateTime).format(
            ViewDateFormat
          )} - ${moment(item.registrationEndDateTime).format(ViewDateFormat)}`,
          playingDates: `${moment(item.startDateTime).format(
            ViewDateFormat
          )} - ${moment(item.endDateTime).format(ViewDateFormat)}`,
        })),
      };

      setData(obj);
    }
    };

    const renderScheduleButton = (isSchedule, id) => {
        if (!isSchedule) return <div></div>;

        return (
            <div>
                <button
                    style={{
                        backgroundColor: "#2b2b8a",
                        color: "white",
                        padding: "6px 14px",
                        borderRadius: "5px",
                        border: "none",
                        cursor: "pointer",
                        fontSize: "14px",
                        fontWeight: "bold",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                    onClick={() => {
                        navigate(`/app/tournamentScheduling?id=${id}`);
                    }}
                >
                    Schedule
                </button>
            </div>
        );
    };


  const _addUpdateTrigger = (id) => (
    <>
      <Button
        size="icon"
        variant="outline"
        color="secondary"
        className="h-7 w-7 mr-2"
        onClick={() => navigate(`/app/addTournament?id=${id}`)}
      >
        <Icon icon="heroicons:pencil" className="h-4 w-4" />
      </Button>
    </>
  );

  const actions = [
    {
      type: "edit",
      onClick: (val) => {
        console.log(val);
        navigate(`/app/addTournament?id=${val.id}`);
      },
    },
    //{
    //  type: "view",
    //  visibleProp: "isSchedule",
    //  visibleValue: true,
    //  onClick: (val) => {
    //    navigate(`/app/tournamentScheduling?id=${val.id}`);
    //  },
    //},
    // {
    //   type: "customIcon",
    //   icon: "check",
    //   onClick: (val) => {
    //     alert("Testing ");
    //   },
    // },
  ];

  let columns = [
    { title: "Icon", type: "image", key: "icon" },
    { title: "Tournament Name", type: "text", key: "name" },
    { title: "Sport Type", type: "text", key: "sportName" },
    { title: "Join Status", type: "text", key: "joinStatus" },
    { title: "Entry Fee", type: "text", key: "entryFee" },
    { title: "Type", type: "text", key: "venueType" },
    { title: "Registration Dates", type: "text", key: "registrationDates" },
    { title: "Playing Dates", type: "text", key: "playingDates" },
    { title: "Schedule", type: "html", key: "schedule" },
  ];

  const _addBtn = () => (
    <Button onClick={() => navigate("/app/addTournament")}>
      <span className="text-xl mr-1">
        <Icon icon="icon-park-outline:plus" />
      </span>
      Add Tournament
    </Button>
  );

  return (
    <>
      {data?.data && (
        <AdvanceTable
          title={"Tournaments"}
          columns={columns}
          data={data}
          getData={getData}
          addButton={_addBtn}
          showPagination
          showAddButton
          actionButtons={actions}
          updateTrigger={_addUpdateTrigger} 
        />
      )}
    </>
  );
};

export default Tournaments;
